const openDidomi = () => {
  if (window.Didomi) {
    window.Didomi.notice.show()
  } else {
    setTimeout(openDidomi, 1000)
  }
}

export default ({ query }) => {
  if (query.action && query.action === 'open-didomi') {
    openDidomi()
  }
}
