<template>
  <a
    :href="linkObject ? href : simpleUrl"
    :target="linkObject ? target : false"
    :class="['PLink', color, { arrow_link: isArrowed, highlight: isHighlight }]"
    data-luko-tracking="PLink"
    @click="!linkObject ? $emit('on-click') : null"
  >
    <ArrowRightIcon :class="`icon-${color}-500`" />
    <slot />
  </a>
</template>

<script>
import ArrowRightIcon from 'LkIcons/ArrowRight.vue'

import linkResolver from '~/utils/prismic/link-resolver'

export default {
  name: 'PLink',

  components: {
    ArrowRightIcon,
  },

  props: {
    linkObject: {
      type: Object,
      default: null,
    },
    simpleUrl: {
      type: String,
      default: '',
    },
    targetedSection: {
      type: String,
      default: '',
    },
    isArrowed: {
      type: Boolean,
      default: false,
    },
    isHighlight: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'gray',
    },
  },

  computed: {
    isMedia() {
      return this.linkObject.link_type === 'Media'
    },

    href() {
      if (this.targetedSection)
        return `#${this.targetedSection.split('#').join('')}`
      return linkResolver(this.linkObject)
    },

    target() {
      if (this.isMedia) return '_blank'
      if (this.linkObject) return this.linkObject.target
      return null
    },
  },
}
</script>
