/**
 * To learn more about Link Resolving check out the Prismic documentation
 * https://prismic.io/docs/vuejs/beyond-the-api/link-resolving
 */

import domainPages from '~/config/router/domain-pages'

const getErrorPagePath = (lang) =>
  domainPages[process.env.CURRENT_DOMAIN].error_page[lang]

const getPagePath = ({ type, lang, uid }) => {
  // remove uid from advice_hub
  if (type === 'hub_page') uid = ''

  const currentDomain = lang?.split('-')[1]
  const typePages = currentDomain ? domainPages[currentDomain] : null
  const langsPages = typePages ? typePages[type] : null
  const path = langsPages ? langsPages[lang] : null

  return path
    ? [path, uid]
        .filter((e) => e)
        .join('/')
        .concat('/') // add trailing slash
        .replace(/\/\//g, '/')
    : getErrorPagePath(lang)
}

export default ({ url, link_type, isBroken, type, lang, uid }) => {
  const shouldReturnUrl = url && ['Media', 'Web'].includes(link_type)

  if (shouldReturnUrl) return url
  if (isBroken) return getErrorPagePath(lang)

  return getPagePath({ type, lang, uid })
}
