import { mapState } from 'vuex'

import { getFormatedLink } from '~/mixins/getFormatedLink'

export const footerMixins = {
  mixins: [getFormatedLink],
  methods: {
    switchLangFormattedUrl(lang) {
      lang = lang.toLowerCase()
      const alterPage = this.alternateLanguages?.find(
        (item) => item.lang === lang
      )
      return this.getFormatedLink({
        link_type: 'Document',
        type: alterPage?.type || 'home_page',
        lang,
        uid: alterPage?.uid,
      })
    },
    openDidomi() {
      window.Didomi.notice.show()
    },
  },
  computed: {
    ...mapState(['alternateLanguages']),
    availableLocales() {
      return this.$i18n.locales.filter(
        (locale) => locale.code !== this.$i18n.locale
      )
    },

    currentLocale() {
      return this.$i18n.locales.find(
        (locale) => locale.code === this.$i18n.locale
      )
    },

    sortedFooterLists() {
      const result = this.data.body.reduce(function (sortedLists, currentList) {
        sortedLists[currentList.primary.footer_list_category] =
          sortedLists[currentList.primary.footer_list_category] || []

        sortedLists[currentList.primary.footer_list_category].push(currentList)

        return sortedLists
      }, Object.create(null))

      return result
    },
  },
}
