export default {
  actions: {
    async nuxtServerInit({ dispatch, commit }, { app }) {
      const lang = app.i18n.localeProperties.prismicIso
      await Promise.all(
        [
          'setHeaderData',
          'setFooterData',
          'setErrorData',
          'setSeoData',
          'setAdviceCategoryData',
        ].map((action) => dispatch(action, lang))
      )
      if (process.env.HAS_WAITLIST === 'true') commit('SET_HAS_WAITLIST', true)
      if (!process.env.CURRENT_DOMAIN)
        throw new Error('CURRENT_DOMAIN env missing')
      commit('SET_CURRENT_DOMAIN', process.env.CURRENT_DOMAIN)
      commit('SET_URL', process.env.URL)
    },

    async setHeaderData({ commit }, lang) {
      const { data: headerData } = await this.$prismic.api.getSingle(
        'mobile-navigation',
        {
          lang,
        }
      )
      commit('header/SET_HEADER_DATA', headerData)
    },

    async setFooterData({ commit }, lang) {
      const { data: footerBody } = await this.$prismic.api.getSingle(
        'website_footer',
        {
          lang,
        }
      )
      commit('SET_FOOTER_DATA', footerBody)
    },

    async setErrorData({ commit }, lang) {
      try {
        const { data: errorData } = await this.$prismic.api.getSingle(
          'error_page',
          {
            lang,
          }
        )
        commit('SET_ERROR_DATA', errorData)
      } catch (error) {
        console.log(error)
      }
    },

    async setSeoData({ commit }, lang) {
      try {
        const { data: seoData } = await this.$prismic.api.getSingle('seo', {
          lang,
        })
        commit('SET_SEO_DATA', seoData.body)
      } catch (error) {
        console.error(`Default SEO doesn't exist in ${lang}`)
      }
    },

    async setAdviceCategoryData({ commit }, lang) {
      try {
        const { results: categoriesPages } = await this.$prismic.api.query(
          this.$prismic.predicates.at('document.type', 'advices_category_page'),
          {
            lang,
          }
        )
        const allCategoryData = categoriesPages.reduce((acc, category) => {
          acc[category.id] = {
            uid: category.uid,
            category_name: category.data.category_name,
          }
          return acc
        }, {})
        commit('SET_ADVICE_CATEGORY_DATA', allCategoryData)
      } catch (error) {
        console.error('Fail to fetch advice cagetories')
      }
    },

    setAlternateLanguages({ commit }, list) {
      commit('SET_ALTERNATE_LANGUAGES', list)
    },
  },

  state() {
    return {
      headerData: null,
      footerData: null,
      seoData: null,
      errorData: null,
      alternateLanguages: [],
      translationUid: null,
      defaultUid: null,
      hasWaitlist: false,
      currentDomain: null, // FR, ES, ...
      url: '',
      navCta: { url: '', text: '' },
      categoryUid: null,
    }
  },

  mutations: {
    SET_FOOTER_DATA(state, data) {
      state.footerData = data
    },

    SET_SEO_DATA(state, data) {
      state.seoData = data
    },

    SET_ERROR_DATA(state, data) {
      state.errorData = data
    },

    SET_ADVICE_CATEGORY_DATA(state, data) {
      state.adviceCategoryData = data
    },

    SET_ALTERNATE_LANGUAGES(state, list) {
      state.alternateLanguages = list
    },

    SET_HAS_WAITLIST(state, bool) {
      state.hasWaitlist = bool
    },

    SET_CURRENT_DOMAIN(state, domain) {
      state.currentDomain = domain
    },

    SET_URL(state, url) {
      state.url = url
    },

    SET_NAV_CTA(state, { url, text }) {
      state.navCta = { url, text }
    },

    SET_PAGE_CATEGORY(state, categoryUid) {
      state.categoryUid = categoryUid
    },
  },
}
