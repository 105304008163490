const parseCookie = (cookies) =>
  cookies.split(';').map((cookie) => {
    const [name, value] = cookie.split('=')
    return {
      name: name?.trim(),
      value: value?.trim(),
    }
  })

const getExpires = (option) => {
  if (option.remove) {
    return 'expires=Thu, 01 Jan 1970 00:00:01 GMT'
  }
  if (option.session) {
    return ''
  }

  const expiryDate = new Date()
  expiryDate.setTime(expiryDate.getTime() + 365 * 24 * 60 * 60 * 1000)
  return `expires=${expiryDate.toUTCString()}`
}

const updateCookie = (key, value, option) => {
  const cookie = key + '=' + value + ';'
  const expires = getExpires(option)

  document.cookie = [cookie, expires, 'path=/', `domain=.luko.eu`].filter((e) => e).join(';')
}

export const getCookie = (key) => {
  return parseCookie(document.cookie).find((cookie) => cookie.name === key)?.value
}
export const setCookie = (key, value, option) => {
  updateCookie(key, JSON.stringify(value), option)
}

export const removeCookie = (key) => {
  updateCookie(key, '', { remove: true })
}
