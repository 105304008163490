<template>
  <footer :class="$style.container">
    <div :class="$style.wrapper">
      <div
        :class="$style.language"
        :style="`background-image: url(${data.footer_language_icon.url})`"
      >
        <div :class="$style.dropDownContainer">
          <label :class="$style.dropdown" for="dropdownCheckbox">
            <span>{{ data.footer_language_label }}</span>
            {{ currentLocale.name }}
          </label>
          <input
            id="dropdownCheckbox"
            type="checkbox"
            :class="$style.dropdownCheckbox"
          />
          <ul :class="$style.languageDropdown">
            <li v-for="locale in availableLocales" :key="locale.code">
              <a :href="switchLangFormattedUrl(locale.prismicIso)">
                {{ locale.name }}
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div :class="$style.footerMain">
        <div :class="$style.footerSide">
          <div :class="$style.lukoId">
            <PImage
              v-if="data.footer_luko_logo && data.footer_luko_logo.url"
              :image-object="data.footer_luko_logo"
              loading="lazy"
            />
            <a
              :href="
                data.footer_luko_email_link && data.footer_luko_email_link.url
              "
            >
              {{ data.footer_luko_email_text }}
            </a>
            <a :href="data.footer_luko_address_link.url">
              {{ data.footer_luko_address_text }}
            </a>
          </div>
          <div :class="$style.bCorp">
            <PImage
              v-if="data.footer_bcorp_logo && data.footer_bcorp_logo.url"
              :image-object="data.footer_bcorp_logo"
              loading="lazy"
            />
            <p>{{ data.footer_bcorp_text }}</p>
          </div>
        </div>

        <div :class="$style.footerSlices">
          <div
            v-for="(list, listIndex) in sortedFooterLists"
            :key="'slice-' + listIndex"
            :class="$style.footerSlice"
          >
            <template v-for="(item, itemIndex) in list">
              <p
                v-if="item.primary.footer_link_list_title"
                :key="'item-title' + itemIndex"
                :class="$style.sliceTitle"
              >
                {{ item.primary.footer_link_list_title }}
              </p>
              <ul
                :key="'item-list' + itemIndex"
                :class="[
                  { [$style.linkList]: item.primary.footer_link_list_title },
                  {
                    [$style.imageList]: !item.primary.footer_link_list_title,
                  },
                ]"
              >
                <li
                  v-for="(link, linkIndex) in item.items"
                  :key="'list-' + linkIndex"
                >
                  <template
                    v-if="
                      link.footer_link_list_item_text &&
                      link.footer_link_list_item_url
                    "
                  >
                    <a
                      v-if="
                        link.footer_link_list_item_url.url &&
                        link.footer_link_list_item_url.url.includes('Didomi')
                      "
                      @click="openDidomi()"
                    >
                      {{ link.footer_link_list_item_text }}
                    </a>
                    <a
                      v-else
                      :href="getFormatedLink(link.footer_link_list_item_url)"
                    >
                      {{ link.footer_link_list_item_text }}
                    </a>
                  </template>
                  <template v-else>
                    <a :href="getFormatedLink(link.footer_image_list_item_url)">
                      <PImage
                        v-if="link.footer_image_list_item_image.url"
                        :image-object="link.footer_image_list_item_image"
                        loading="lazy"
                      />
                    </a>
                  </template>
                </li>
              </ul>
            </template>
          </div>
        </div>
      </div>
      <!-- /.footer-main -->
      <p :class="$style.legal">
        {{ data.footer_legal_text }}
      </p>
    </div>
  </footer>
</template>

<script>
import { footerMixins } from '~/mixins/footer'

export default {
  name: 'Footer2021Q4',

  mixins: [footerMixins],

  props: {
    data: {
      type: Object,
      default: null,
    },
  },
}
</script>

<style lang="scss" module>
.container {
  background: $bluko-500;
}

.wrapper {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 20px 24px;

  @include above(small) {
    padding-bottom: 32px;
  }
}

.footerMain {
  padding: 48px 0;

  @include above(small) {
    display: flex;
    padding: 32px 0;
  }
}

.footerSide {
  width: 260px;
}

.lukoId {
  img {
    height: 64px;
    margin-bottom: 8px;
  }

  a {
    display: block;

    color: white;
    line-height: 32px;

    text-decoration: none;

    opacity: 0.5;

    &:hover {
      text-decoration: underline;
    }
  }
}

.bCorp {
  display: flex;
  margin-top: 32px;

  opacity: 0.5;
  img {
    flex-shrink: 0;
    height: 80px;
  }
  p {
    margin-left: 16px;

    color: white;
    font-size: 14px;
    line-height: 20px;
  }
}

.footerSlices {
  @include above(small) {
    display: flex;
    flex-wrap: wrap;
    margin-left: 5%;
  }
}

.footerSlice {
  margin-top: 56px;

  @include above(small) {
    width: 260px;
    margin-left: 24px;
  }

  a {
    display: block;
    margin-top: 8px;

    color: #ffffff;
    line-height: 24px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    img {
      display: block;
    }
  }
}

.sliceTitle {
  margin-bottom: 8px;

  color: white;
  font-weight: 500;
  line-height: 24px;

  opacity: 0.5;
}

.imageList {
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;
  margin-left: -16px;

  li {
    margin-left: 16px;
    img {
      display: block;
    }
  }
}

.legal {
  padding-top: 32px;
  border-top: 1px solid #ffffff1a;

  color: #ffffff80;
  font-size: $font-size;
  font-size: 14px;
  line-height: 20px;
}

.language {
  position: relative;

  border-bottom: 1px solid #ffffff1a;

  color: white;
  font-weight: 500;
  font-size: 16px;

  background: no-repeat left center;
}

.dropDownContainer {
  position: relative;

  display: inline-block;
  padding-left: 30px;
}

.dropdown {
  display: inline-block;
  padding: 32px 0;

  cursor: pointer;

  span {
    margin-right: 0.5em;

    font-weight: 400;

    opacity: 0.7;
  }

  &::after {
    display: inline-block;
    width: 7px;
    height: 4px;
    margin-left: 7px;

    vertical-align: middle;

    background: url(~assets/icons/footer/caret.svg) no-repeat;

    content: '';
  }
}

.languageDropdown {
  position: absolute;
  top: 56px;
  left: 0px;
  z-index: 9;

  display: block;
  width: 100%;
  border-radius: 3px;
  overflow: hidden;

  color: $bluko-1000;
  font-weight: 500;

  background: white;
  opacity: 0;

  transition: 0.3s all ease;

  pointer-events: none;

  a {
    display: block;
    padding: 15px;

    color: $gray-1000;
    text-decoration: none;

    cursor: pointer;

    transition: background-color 0.3s;

    &.selected {
      padding-left: 30px;

      background: no-repeat 10px center;

      &:hover {
        background: #d9e8fe no-repeat 10px center;
      }
    }

    &:hover {
      text-decoration: none;

      background: #d9e8fe;
    }
  }
}

.dropdownCheckbox {
  display: none;

  &:checked ~ .languageDropdown {
    top: 64px;

    opacity: 1;

    pointer-events: all;
  }
}

a {
  cursor: pointer;
}
</style>
