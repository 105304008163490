// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/icons/header/menu-pointer.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProfileDropdown__profileRoot___2AlRW{position:relative}.ProfileDropdown__profileButton___3jl2U{padding:0;border:none;background:none;cursor:pointer}.ProfileDropdown__profileButton___3jl2U,.ProfileDropdown__profileInitials___TOIj0{display:inline-flex;align-items:center}.ProfileDropdown__profileInitials___TOIj0{flex-shrink:0;justify-content:center;width:40px;height:40px;border-radius:50%;color:#6b6651;font-weight:500;font-size:14px;line-height:20px;text-align:center;text-transform:uppercase;background-color:#eae9e3}.ProfileDropdown__profileChevron___U53_X{flex-shrink:0;width:10px;height:6px;margin-left:6px}.ProfileDropdown__profileDropdown___2b9rV{position:absolute;top:48px;right:0;width:312px;padding:16px 0;border-radius:4px;background-color:#fff;box-shadow:0 2px 16px rgba(0,0,108,.12)}.ProfileDropdown__profileDropdown___2b9rV:before{position:absolute;top:8px;right:12px;width:24px;height:8px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");transform:translate(-50%,-16px);transition-duration:.3s;transition-property:opacity,transform;content:\"\"}.ProfileDropdown__profileDropdownHr___1NOzu{margin:16px 0;border:none;border-top:1px solid #efeee9}.ProfileDropdown__profileDropdownItem___2ft0T{display:flex;align-items:center;box-sizing:border-box;width:100%;padding:12px 40px;border:none;color:#293148;font-weight:500;font-size:16px;line-height:24px;text-decoration:none;background-color:#fff;cursor:pointer;transition:background-color .2s linear}.ProfileDropdown__profileDropdownItem___2ft0T:hover{background-color:#f4f3ef}.ProfileDropdown__profileDropdownItemIcon___1lf6s{display:inline-flex;flex-shrink:0;align-items:center;justify-content:center;width:24px;height:24px;margin-right:16px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"profileRoot": "ProfileDropdown__profileRoot___2AlRW",
	"profileButton": "ProfileDropdown__profileButton___3jl2U",
	"profileInitials": "ProfileDropdown__profileInitials___TOIj0",
	"profileChevron": "ProfileDropdown__profileChevron___U53_X",
	"profileDropdown": "ProfileDropdown__profileDropdown___2b9rV",
	"profileDropdownHr": "ProfileDropdown__profileDropdownHr___1NOzu",
	"profileDropdownItem": "ProfileDropdown__profileDropdownItem___2ft0T",
	"profileDropdownItemIcon": "ProfileDropdown__profileDropdownItemIcon___1lf6s"
};
module.exports = ___CSS_LOADER_EXPORT___;
