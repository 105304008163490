<template>
  <div
    :class="{
      [$style.root]: true,
      [$style.rootIsDanger]: isDanger,
      [$style.rootIsSuccess]: isSuccess,
      [$style.rootIsWarning]: isWarning,
      [$style.rootIsSmall]: isSmall,
    }"
  >
    <component
      :is="icon"
      :class="[$style.rootIcon, color]"
      :width="size"
      :height="size"
    ></component>
    <div :class="$style.rootContent">
      <slot />
    </div>
    <button
      v-if="isDismissible"
      :class="$style.rootCloseBtn"
      type="button"
      @click="$emit('dismiss')"
    >
      <CrossIcon
        :class="[$style.rootCloseBtnIcon, color]"
        :width="size"
        :height="size"
      />
    </button>
  </div>
</template>

<script>
import CrossIcon from 'LkIcons/Cross.vue'
import InfoIcon from 'LkIcons/Info.vue'
import WarningIcon from 'LkIcons/Warning.vue'
import CheckIcon from 'LkIcons/Check.vue'

export default {
  name: 'LkInfoBox',
  components: { CrossIcon, InfoIcon, WarningIcon, CheckIcon },
  props: {
    isDismissible: { type: Boolean, default: false },
    type: { type: String, default: 'info' },
    isSmall: { type: Boolean, default: false },
  },
  emits: ['dismiss'],
  computed: {
    isDanger() {
      return this.type === 'danger'
    },
    isSuccess() {
      return this.type === 'success'
    },
    isWarning() {
      return this.type === 'warning'
    },
    size() {
      return this.isSmall ? 16 : 24
    },
    color() {
      if (this.isDanger) return 'icon-terracota-500'
      if (this.isSuccess) return 'icon-mint-500'
      if (this.isWarning) return 'icon-orange-500'
      return 'icon-bluko-500'
    },
    icon() {
      if (this.isDanger) return 'WarningIcon'
      if (this.isSuccess) return 'CheckIcon'
      if (this.isWarning) return 'WarningIcon'
      return 'InfoIcon'
    },
  },
}
</script>

<style lang="scss" module>
.root {
  @include typo_body;

  display: flex;
  align-items: center;
  width: 100%;

  padding: 16px 24px;

  border-radius: 8px;

  color: $bluko-500;

  background-color: $bluko-100;
}

.rootIcon {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}

.rootContent {
  flex-grow: 1;
  width: fit-content;
}

.rootCloseBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  margin-left: 24px;
  padding: 0;
  border: none;

  background: none;
  cursor: pointer;
}

.rootCloseBtnIcon {
  width: 24px;
  height: 24px;
}

.rootIsDanger {
  color: $terracota-500;

  background-color: $terracota-100;
}

.rootIsWarning {
  color: $orange-500;

  background-color: $orange-100;
}

.rootIsSuccess {
  color: $mint-500;

  background-color: $mint-100;
}

.rootIsSmall {
  padding: 14px 16px;
}

.rootIsSmall .rootIcon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.rootIsSmall .rootContent {
  @include typo_sub-text;
}

.rootIsSmall .rootCloseBtn {
  margin-left: 8px;
}

.rootIsSmall .rootCloseBtnIcon {
  width: 16px;
  height: 16px;
}
</style>
