<template>
  <div id="mainRoot" :class="$style.mainRoot" data-luko-tracking="App-website">
    <transition name="fade-down">
      <InfoBox
        v-if="notification.text"
        :type="notification.type"
        :class="$style.infoBox"
        is-dismissible
        @dismiss="onDismiss"
      >
        {{ notification.text }}
      </InfoBox>
    </transition>

    <HeaderModule
      v-if="!$store.state.page.settings.removeHeader"
      :data="headerData"
      :class="$style.header"
    />
    <main :class="$style.content">
      <nuxt />
    </main>
    <FooterModule
      v-if="!$store.state.page.settings.removeFooter"
      :data="footerData"
    />
  </div>
</template>

<script>
import FooterModule from '~/modules/Footer'
import HeaderModule from '~/modules/Header'
import { formatMetaDatas } from '~/utils/metaData'
import InfoBox from '~/components/InfoBox'

export default {
  name: 'DefaultLayout',

  head() {
    const i18nSeo = this.$nuxtI18nSeo()
    const canonical = {
      rel: 'canonical',
      href: `${process.env.URL}${this.$route.path}/`.replace(/\/\/$/, '/'),
    }
    const defaultSeo = formatMetaDatas(
      this.$store.state.seoData || [],
      this.$route.path
    )

    return {
      htmlAttrs: {
        ...i18nSeo.htmlAttrs,
      },
      title: defaultSeo.title,
      meta: [...i18nSeo.meta, ...defaultSeo.meta],
      link: [canonical],
    }
  },

  components: {
    InfoBox,
    HeaderModule,
    FooterModule,
  },

  data() {
    return {
      headerData: this.$store.state.header.headerData,
      footerData: this.$store.state.footerData,
    }
  },

  computed: {
    notification() {
      return this.$store.state.notification
    },
  },
  methods: {
    onDismiss() {
      return this.$store.commit('notification/SET_NOTIFICATION', { text: '' })
    },
  },
}
</script>

<style lang="scss" module>
.mainRoot {
  overflow-x: hidden;
  overflow-y: none;
}

.content {
  margin: 0 auto;
}

.header {
  margin: 0 auto;
}
.infoBox {
  position: fixed;
  top: 0;
  z-index: 999;
}
</style>
<style lang="scss">
.fade-down-enter-active,
.fade-down-leave-active {
  transform: translateY(0);

  transition: opacity 0.3s, transform 0.3s;
}
.fade-down-enter, .fade-down-leave-to /* .fade-leave-active below version 2.1.8 */ {
  transform: translateY(-8px);

  opacity: 0;
}
</style>
