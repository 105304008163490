import AOS from 'aos'

export default ({ app }) => {
  // eslint-disable-next-line new-cap
  app.AOS = new AOS.init({
    duration: 800,
    easing: 'ease-in-out-cubic',
    mirror: true,
    offset: 60,
  })
}
