// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Error__container___3xzNl{max-width:inherit;margin-top:-64px;padding:0 24px;background-color:#fff4ee}.Error__inner___Fv2LL{display:flex;flex-direction:column;align-items:center;min-height:720px;padding-top:192px;text-align:center;background-repeat:no-repeat;background-position:100% 100%;background-size:auto}@media (max-width:719px){.Error__inner___Fv2LL{min-height:630px;padding-top:132px;text-align:left;background-size:70vw 60vw}}.Error__desc___2_G3E{margin:0}@media (max-width:959px){.Error__desc___2_G3E{margin:0 auto}}.Error__title___hSdfD h1{max-width:725px;margin:0 auto 16px;color:#2c2302;font-weight:500;font-size:52px;line-height:64px}@media (max-width:959px){.Error__title___hSdfD h1{font-size:40px;line-height:56px}}.Error__subtitle___Vvpny{margin:16px 0 48px;color:#6b6651;font-size:20px;line-height:32px}@media (max-width:959px){.Error__subtitle___Vvpny{font-size:16px;line-height:24px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"container": "Error__container___3xzNl",
	"inner": "Error__inner___Fv2LL",
	"desc": "Error__desc___2_G3E",
	"title": "Error__title___hSdfD",
	"subtitle": "Error__subtitle___Vvpny"
};
module.exports = ___CSS_LOADER_EXPORT___;
