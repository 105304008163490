<template>
  <component :is="tag" :class="$style.root" data-luko-tracking="Wrapper">
    <slot />
  </component>
</template>

<script>
export default {
  name: 'Wrapper',
  props: {
    tag: {
      type: String,
      default: 'div',
    },
  },
}
</script>

<style lang="scss" module>
.root {
  max-width: 1280px;
  margin: 0 auto;
}
</style>
