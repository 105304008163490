var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[_vm.$style.desktopNav, ( _obj = {}, _obj[_vm.$style.isLight] = _vm.isLight, _obj )],attrs:{"data-luko-tracking":"desktop-nav"}},[_c('nav',{class:_vm.$style.desktopOnly},[_c('ul',{class:_vm.$style.menuList},[_vm._l((_vm.sortedMenuLists),function(list,listIndex){return _c('li',{key:listIndex,class:[_vm.$style.menuItem, _vm.$style.menuItemSubListed]},[_c('span',[_vm._v("\n          "+_vm._s(list[0].primary.menu_category || list[0].primary.menu_list_title)+"\n        ")]),_vm._v(" "),_c('div',{class:_vm.$style.subListContainer},_vm._l((list),function(subList,subListIndex){
var _obj;
return _c('ul',{key:subListIndex,class:[
              _vm.$style.subList,
              ( _obj = {}, _obj[_vm.$style.secondarySubList] = subList.slice_type === 'secondary_menu_list', _obj ) ]},[(subList.primary.menu_list_title)?_c(_vm.hasLink(subList.primary.menu_list_title_link) ? 'a' : 'p',{tag:"component",class:_vm.$style.subListTitle,attrs:{"href":_vm.hasHref(subList.primary.menu_list_title_link)}},[_vm._v("\n              "+_vm._s(subList.primary.menu_list_title)+"\n            ")]):_vm._e(),_vm._v(" "),_vm._l((subList.items),function(link,linkIndex){
            var _obj;
return _c('li',{key:linkIndex},[(subList.slice_type !== 'secondary_menu_list')?_c('a',{class:( _obj = {}, _obj[_vm.$style.haveTag] = link.is_new_item, _obj ),attrs:{"href":_vm.getFormatedLink(link.menu_item_url)}},[(link.menu_item_icon && link.menu_item_icon.url)?_c('PImage',{attrs:{"image-object":link.menu_item_icon,"loading":"lazy","sizes":"xs:24px"}}):_vm._e(),_vm._v(" "),_c('span',[_c('span',{class:_vm.$style.itemName},[_vm._v("\n                    "+_vm._s(link.menu_item_name)+"\n                  ")]),_vm._v(" "),(link.is_new_item)?_c('span',{class:_vm.$style.tag},[_vm._v("\n                    "+_vm._s(_vm.$t('new'))+"\n                  ")]):_vm._e()])],1):_c('a',{attrs:{"href":_vm.getFormatedLink(link.secondary_menu_item_link),"target":"_blank"}},[_vm._v("\n                "+_vm._s(link.secondary_menu_item_name)+"\n              ")])])})],2)}),0)])}),_vm._v(" "),_vm._l((_vm.data.desktop_links),function(desktopLink,desktopLinkIndex){return _c('li',{key:desktopLinkIndex,class:[_vm.$style.menuItem, _vm.$style.otherMenuItem]},[_c('a',{attrs:{"href":_vm.getFormatedLink(desktopLink.desktop_link_url)}},[_vm._v("\n          "+_vm._s(desktopLink.desktop_link_name)+"\n        ")])])})],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }