export const state = () => ({
  values: {
    HomePageCTA: {
      value: process.env.CURRENT_DOMAIN === 'de' ? 'Cards' : 'Address',
    },
  },
})

export const mutations = {
  UPDATE(state, payload) {
    state.values[payload.key] = payload
  },
  DELETE(state, key) {
    delete state.values[key]
  },
}
