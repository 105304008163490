var render = function () {
var _obj, _obj$1, _obj$2, _obj$3, _obj$4, _obj$5, _obj$6, _obj$7, _obj$8, _obj$9, _obj$10, _obj$11, _obj$12, _obj$13;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.component,_vm._g(_vm._b({tag:"component",class:[
    ( _obj = {}, _obj[_vm.$style.root] = true, _obj ),
    ( _obj$1 = {}, _obj$1[_vm.$style['root--fluid']] = _vm.isFluid, _obj$1 ),
    ( _obj$2 = {}, _obj$2[_vm.$style['root--fluid-below-small']] = _vm.isFluidBelowSmall, _obj$2 ),
    ( _obj$3 = {}, _obj$3[_vm.$style['root--light']] = _vm.isLight, _obj$3 ),
    ( _obj$4 = {}, _obj$4[_vm.$style['root--small']] = _vm.isSmall, _obj$4 ),
    ( _obj$5 = {}, _obj$5[_vm.$style['root--danger']] = _vm.isDanger, _obj$5 ),
    ( _obj$6 = {}, _obj$6[_vm.$style['root--success']] = _vm.isSuccess, _obj$6 ),
    ( _obj$7 = {}, _obj$7[_vm.$style['root--untouched']] = _vm.isUntouched, _obj$7 ),
    ( _obj$8 = {}, _obj$8[_vm.$style['root--secondary']] = _vm.isSecondary, _obj$8 ),
    ( _obj$9 = {}, _obj$9[_vm.$style['root--white']] = _vm.isWhite, _obj$9 ),
    ( _obj$10 = {}, _obj$10[_vm.$style['root--warning']] = _vm.isWarning, _obj$10 ),
    ( _obj$11 = {}, _obj$11[_vm.$style['root--is-loading']] = _vm.isLoading, _obj$11 ),
    ( _obj$12 = {}, _obj$12[_vm.$style['root--outlined']] = _vm.isOutlined, _obj$12 ) ],attrs:{"data-luko-tracking":_vm.dataLukoTracking,"to":_vm.to,"href":_vm.href,"disabled":_vm.isDisabled,"target":_vm.target ? _vm.target : '_self',"rel":_vm.rel}},'component',_vm.$attrs,false),_vm.$listeners),[(_vm.$slots.icon)?_c('span',{class:[_vm.iconColor, _vm.$style.icon, ( _obj$13 = {}, _obj$13[_vm.$style['icon-right']] = _vm.hasIconRight, _obj$13 )]},[_vm._t("icon")],2):_vm._e(),_vm._v(" "),_c('span',{class:_vm.$style.slot},[_vm._t("default")],2),_vm._v(" "),_c('svg',{attrs:{"width":"0","height":"0"}},[_c('clipPath',{attrs:{"id":"LK_button-clip"}},[_c('path',{attrs:{"d":"M14 4C8.47715 4 4 8.47715 4 14C4 19.5228 8.47715 24 14 24C19.5228 24 24 19.5228 24 14C24 12.8954 24.8954 12 26 12C27.1046 12 28 12.8954 28 14C28 21.732 21.732 28 14 28C6.26801 28 0 21.732 0 14C0 6.26801 6.26801 0 14 0C15.1046 0 16 0.89543 16 2C16 3.10457 15.1046 4 14 4Z","fill":"#000"}})])])])}
var staticRenderFns = []

export { render, staticRenderFns }