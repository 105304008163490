const metaMap = [
  {
    id: 'meta_description',
    type: 'description',
  },
  {
    id: 'open_graph_title',
    type: 'og:title',
  },
  {
    id: 'open_graph_image',
    type: 'og:image',
  },
  {
    id: 'open_graph_description',
    type: 'og:description',
  },
  {
    id: 'open_graph_url',
    type: 'og:url',
    default: (path) => `${process.env.URL}${path}/`.replace(/\/\/$/, '/'),
  },
  {
    id: 'open_graph_type',
    type: 'og:type',
    default: 'website',
  },
  {
    id: 'robots_index',
    type: 'robots',
  },
  {
    id: 'twitter_card',
    type: 'twitter:card',
    default: 'summary_large_image',
  },
  {
    id: 'twitter_site',
    type: 'twitter:site',
    default: '@getluko',
  },
  {
    id: 'twitter_title',
    type: 'twitter:title',
  },
  {
    id: 'twitter_description',
    type: 'twitter:description',
  },
  {
    id: 'twitter_image',
    type: 'twitter:image',
  },
  {
    id: 'twitter_url',
    type: 'twitter:url',
    default: (path) => `${process.env.URL}${path}/`.replace(/\/\/$/, '/'),
  },
]

const formattedMeta = (primarySliceContent, path, customDefaultDatas) =>
  metaMap
    .map((e) => {
      let content

      if (e.default) {
        content = e.default

        if (e.id === 'open_graph_url' || e.id === 'twitter_url')
          content = e.default(path)
      }

      if (customDefaultDatas && customDefaultDatas[e.id])
        content = customDefaultDatas[e.id]

      if (primarySliceContent[e.id]) {
        if (e.id === 'open_graph_image') {
          content = primarySliceContent[e.id]?.url
        } else if (
          e.id === 'meta_description' &&
          primarySliceContent[e.id] === 'Meta Description' // Filter all annoying default values in BO
        ) {
          content = ''
        } else {
          content = primarySliceContent[e.id]
        }
      }

      if (content)
        return e.type.includes('twitter:') || e.type === 'description'
          ? { hid: e.type, name: e.type, content }
          : {
              hid: e.type,
              property: e.type,
              content,
            }
      return null
    })
    .filter((e) => e)

export const formatMetaDatas = (
  metaDatas,
  path,
  customDefaultDatas,
  hreflangs = []
) => {
  const flattenedPrismicMeta = {}
  metaDatas.forEach((slice) => {
    Object.assign(flattenedPrismicMeta, slice.primary)
  })

  const title = metaDatas[0]?.primary.meta_title

  return {
    meta: formattedMeta(flattenedPrismicMeta, path, customDefaultDatas),
    title,
    link: [...hreflangs],
  }
}
