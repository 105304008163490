export const excludedRoutes = [
  /\/active-protection(\/)?$/,
  /\/compliance(\/)?$/,
  /\/home-services(\/)?$/,
  /\/versicherung(\/)?$/, // product hub
  /\/insurance(\/)?$/, // product hub
  /\/lukon-termination(\/)?$/,
  /\/partners(\/)?$/,
  /\/perks(\/)?$/,
  /\/press(\/)?$/,
  /\/smart-certificate(\/)?$/,
]

export default {
  advices_category_page: {
    'de-de': '/service',
    'en-de': '/en/advice',
  },
  advice_page: {
    'de-de': '/service/tipps',
    'en-de': '/en/advice/guide',
  },
  contact_page: {
    'de-de': '/kontakt',
    'en-de': '/en/contact',
  },
  error_page: {
    'de-de': '/not-found/',
    'en-de': '/en/not-found/',
  },
  giveback_page: {
    'de-de': '/giveback',
    'en-de': '/en/giveback',
  },
  home_page: {
    'de-de': '/',
    'en-de': '/en',
  },
  hub_page: {
    // advice hub
    'de-de': '/service',
    'en-de': '/en/advice',
  },
  legal_page: {
    'de-de': '/',
    'en-de': '/en',
  },
  product_page: {
    'de-de': '/versicherung',
    'en-de': '/en/insurance',
  },
  refunds_page: {
    'de-de': '/refunds',
    'en-de': '/en/refunds',
  },
  testimonials_page: {
    'de-de': '/kundenbewertungen',
    'en-de': '/en/testimonials',
  },
  email_confirmation_page: {
    'de-de': '/optin/confirm',
    'en-de': '/en/optin/confirm',
  },
}
