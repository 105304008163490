var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[_vm.$style.menuItem, ( _obj = {}, _obj[_vm.$style.menuActive] = _vm.isOpen, _obj )]},[_c('p',{class:_vm.$style.menuItemTitle,on:{"click":function($event){return _vm.toggleMenuItem()}}},[_c('span',[_vm._v("\n      "+_vm._s(_vm.title)+"\n    ")])]),_vm._v(" "),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}],class:_vm.$style.subMenuList},_vm._l((_vm.links),function(link,linkIndex){
var _obj;
return _c('li',{key:linkIndex,class:_vm.$style.subMenuItem},[_c('a',{class:( _obj = {}, _obj[_vm.$style.haveTag] = link.is_new_item, _obj ),attrs:{"href":_vm.getFormatedLink(link.menu_item_url || link.link)}},[(
            (link.menu_item_icon && link.menu_item_icon.url) ||
            (link.icon && link.icon.url)
          )?_c('PImage',{attrs:{"image-object":link.menu_item_icon || link.icon,"loading":"lazy","sizes":"xs:24px"}}):_vm._e(),_vm._v(" "),_c('span',[_c('span',{class:_vm.$style.itemName},[_vm._v("\n            "+_vm._s(link.menu_item_name || link.link_text)+"\n          ")]),_vm._v(" "),(link.is_new_item)?_c('span',{class:_vm.$style.tag},[_vm._v("\n            "+_vm._s(_vm.$t('new'))+"\n          ")]):_vm._e()])],1)])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }