// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".default__mainRoot___3LYoj{overflow-x:hidden;overflow-y:none}.default__content___3uONu,.default__header___1ljsI{margin:0 auto}.default__infoBox___1D58w{position:fixed;top:0;z-index:999}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"mainRoot": "default__mainRoot___3LYoj",
	"content": "default__content___3uONu",
	"header": "default__header___1ljsI",
	"infoBox": "default__infoBox___1D58w"
};
module.exports = ___CSS_LOADER_EXPORT___;
