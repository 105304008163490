<template>
  <div
    data-luko-tracking="mobile-nav"
    :class="[$style.mobileNav, { [$style.mobileNavOpen]: isMobileNavOpen }]"
  >
    <transition name="menu-fade-left">
      <div v-show="isMobileNavOpen" :class="$style.mobileNavContainer">
        <div :class="$style.mobileNavHeader">
          <button
            :class="$style.closeButton"
            @click="$emit('toggle-menu', false)"
          >
            <CrossIcon />
          </button>

          <a v-if="!accountDropdownMenu" :href="getMyLukoLink()">
            <button :class="$style.accountButton">
              {{ data.account_button }}
            </button>
          </a>
        </div>
        <nav :class="$style.menuContainer">
          <ul :class="$style.menuList">
            <li
              v-for="(menu, menuIndex) in sortedMenuLists"
              :key="'menu_' + menuIndex"
            >
              <DropDown
                :title="menu.primary.menu_list_title"
                :links="menu.items"
              />
            </li>
            <li
              v-for="(desktopLink, linkIndex) in data.desktop_links"
              :key="'link_' + linkIndex"
              :class="$style.desktopMenuItem"
            >
              <a :href="getFormatedLink(desktopLink.desktop_link_url)">
                {{ desktopLink.desktop_link_name }}
              </a>
            </li>
            <li v-if="accountDropdownMenu">
              <DropDown
                :title="data.account_button"
                :links="accountDropdownMenu.items"
              />
            </li>
          </ul>
        </nav>
      </div>
    </transition>

    <transition name="menu-backdrop-fade-in">
      <div
        v-show="isMobileNavOpen"
        :class="$style.mobileNavBackdrop"
        @click="$emit('toggle-menu', false)"
      ></div>
    </transition>
  </div>
</template>

<script>
import CrossIcon from 'LkIcons/Cross.vue'

import { getMyLukoLink } from '~/mixins/getMyLukoLink'
import { getFormatedLink } from '~/mixins/getFormatedLink'

import DropDown from './components/DropDown'

export default {
  name: 'MobileNavigation',

  components: {
    DropDown,
    CrossIcon,
  },

  mixins: [getFormatedLink, getMyLukoLink],

  props: {
    data: {
      type: Object,
      required: true,
    },
    isMobileNavOpen: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    sortedMenuLists() {
      return this.data.body.filter((s) => s.slice_type === 'menu_list')
    },
    accountDropdownMenu() {
      return this.data.body.find(
        (s) => s.slice_type === 'account_button_menu_list'
      )
    },
  },

  methods: {
    closeMenus() {
      for (const menu of this.sortedMenuLists) {
        menu.primary.is_menu_open = false
      }
    },
  },
}
</script>

<style lang="scss" module>
.mobileNavContainer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;

  width: calc(100% - 54px);
  max-width: 320px;
  height: 100vh;

  background-color: white;

  transition: transform 0.3s;
}

.mobileNavBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;

  width: 100%;
  height: 100vh;

  background-color: black;
  opacity: 0.4;

  content: '';
}

.mobileNavHeader {
  display: flex;
  justify-content: space-between;
  padding: 10px 16px;
}

.closeButton {
  padding: 4px;
  border: none;
  border-radius: 4px;

  background-color: transparent;
  cursor: pointer;

  img {
    display: block;
  }
}

.accountButton {
  padding: 8px 16px;
  border: none;
  border-radius: 8px;

  font-weight: 500;
  font-size: 14px;

  line-height: 20px;

  cursor: pointer;
}

.desktopMenuItem {
  a {
    display: block;
    padding: 16px;

    color: $gray-700;
    text-decoration: none;

    transition: color 0.3s;

    &:hover {
      color: $gray-1000;
    }
  }
}
</style>
<style lang="scss">
.menu-fade-left-enter-active,
.menu-fade-left-leave-active {
  left: 0;

  transition: opacity 0.3s, left 0.3s;
}
.menu-fade-left-enter, .menu-fade-left-leave-to /* .fade-leave-active below version 2.1.8 */ {
  left: -8px;

  opacity: 0;
}
.menu-backdrop-fade-in-enter-active,
.menu-backdrop-fade-in-leave-active {
  transition: opacity 0.3s;
}
.menu-backdrop-fade-in-enter, .menu-backdrop-fade-in-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
