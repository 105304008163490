<template>
  <div
    data-luko-tracking="desktop-nav"
    :class="[$style.desktopNav, { [$style.isLight]: isLight }]"
  >
    <nav :class="$style.desktopOnly">
      <ul :class="$style.menuList">
        <li
          v-for="(list, listIndex) in sortedMenuLists"
          :key="listIndex"
          :class="[$style.menuItem, $style.menuItemSubListed]"
        >
          <span>
            {{
              list[0].primary.menu_category || list[0].primary.menu_list_title
            }}
          </span>
          <div :class="$style.subListContainer">
            <ul
              v-for="(subList, subListIndex) in list"
              :key="subListIndex"
              :class="[
                $style.subList,
                {
                  [$style.secondarySubList]:
                    subList.slice_type === 'secondary_menu_list',
                },
              ]"
            >
              <component
                :is="hasLink(subList.primary.menu_list_title_link) ? 'a' : 'p'"
                v-if="subList.primary.menu_list_title"
                :class="$style.subListTitle"
                :href="hasHref(subList.primary.menu_list_title_link)"
              >
                {{ subList.primary.menu_list_title }}
              </component>
              <li v-for="(link, linkIndex) in subList.items" :key="linkIndex">
                <a
                  v-if="subList.slice_type !== 'secondary_menu_list'"
                  :href="getFormatedLink(link.menu_item_url)"
                  :class="{ [$style.haveTag]: link.is_new_item }"
                >
                  <PImage
                    v-if="link.menu_item_icon && link.menu_item_icon.url"
                    :image-object="link.menu_item_icon"
                    loading="lazy"
                    sizes="xs:24px"
                  />
                  <span>
                    <span :class="$style.itemName">
                      {{ link.menu_item_name }}
                    </span>
                    <span v-if="link.is_new_item" :class="$style.tag">
                      {{ $t('new') }}
                    </span>
                  </span>
                </a>
                <a
                  v-else
                  :href="getFormatedLink(link.secondary_menu_item_link)"
                  target="_blank"
                >
                  {{ link.secondary_menu_item_name }}
                </a>
              </li>
            </ul>
          </div>
        </li>
        <li
          v-for="(desktopLink, desktopLinkIndex) in data.desktop_links"
          :key="desktopLinkIndex"
          :class="[$style.menuItem, $style.otherMenuItem]"
        >
          <a :href="getFormatedLink(desktopLink.desktop_link_url)">
            {{ desktopLink.desktop_link_name }}
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { getFormatedLink } from '~/mixins/getFormatedLink'
import { getOnboardingLink } from '~/mixins/getOnboardingLink'
import PLink from '~/components/PLink'

export default {
  name: 'DesktopNavigation',

  components: {
    PLink,
  },

  mixins: [getFormatedLink, getOnboardingLink],

  props: {
    data: {
      type: Object,
      required: true,
    },
    isLight: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      contractPrice: null,
      hasWaitlist: this.$store.state.hasWaitlist,
    }
  },

  computed: {
    sortedMenuLists() {
      return this.data.body
        .filter(
          (s) =>
            s.slice_type === 'menu_list' ||
            s.slice_type === 'secondary_menu_list'
        )
        .reduce((sortedMenus, currentMenu) => {
          sortedMenus[currentMenu.primary.menu_category] =
            sortedMenus[currentMenu.primary.menu_category] || []

          sortedMenus[currentMenu.primary.menu_category].push(currentMenu)

          return sortedMenus
        }, Object.create(null))
    },
  },

  mounted() {
    this.getContractPrice()
  },

  methods: {
    getContractPrice() {
      if (window && window.storage) {
        const price = window.storage.get('price')
        if (price !== '0') {
          this.contractPrice = price
        }
      }
    },

    hasLink(linkObject) {
      if (!linkObject) return false
      if (linkObject.type) return true
      if (linkObject.url) return true
      return false
    },

    hasHref(linkObject) {
      if (this.hasLink(linkObject)) return this.getFormatedLink(linkObject)
      return false
    },
  },
}
</script>

<style lang="scss" module>
.desktopNav {
  align-items: center;
  justify-content: space-between;
  margin-left: 32px;

  a {
    color: $gray-1000;
    font-size: 16px;
    line-height: 24px;
    text-decoration: none;

    cursor: pointer;

    transition: color 0.3s;

    img {
      display: block;
      width: 24px;
      height: 24px;
      margin-right: 16px;

      transition: opacity 0.3s;
    }
    &:hover {
      color: $gray-700;
      text-decoration: none;

      img {
        opacity: 0.7;
      }
    }
  }
}

.menuList {
  position: relative;

  display: flex;
}

.menuItem {
  position: relative;

  padding: 16px 24px 16px 8px;

  color: $gray-1000;
  font-size: 16px;
  line-height: 24px;
  &:hover {
    & > span,
    & > a {
      color: $gray-700;

      transition: color 0.3s;
    }
  }

  &.menuItemSubListed > span {
    cursor: pointer;

    &::before {
      position: absolute;
      top: 100%;
      left: calc(50% - 8px);
      z-index: 2;

      width: 24px;
      height: 8px;

      background-image: url('~assets/icons/header/menu-pointer.svg');
      transform: translate(-50%, -16px);
      opacity: 0;

      transition-duration: 0.3s;
      transition-property: opacity, transform;

      content: '';
    }

    &:after {
      position: absolute;
      top: 50%;

      width: 6px;
      height: 6px;

      background-image: url('~assets/icons/header/chevron.svg');

      transform: translate(5px, -50%);

      content: '';
    }
  }
  &:hover {
    &.menuItemSubListed span::before {
      transform: translate(-50%, -8px);
      opacity: 1;
    }
    .subListContainer {
      transform: translateY(0);
      opacity: 1;

      pointer-events: all;
    }
  }
}

.subListContainer {
  position: absolute;
  top: 100%;
  left: -32px;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 16px;

  border-radius: 8px;
  overflow: hidden;

  background-color: white;
  box-shadow: 0px 24px 48px rgba(44, 35, 2, 0.24);
  transform: translateY(-8px);

  opacity: 0;

  transition-duration: 0.3s;
  transition-property: opacity, transform;

  pointer-events: none;
}

.subList {
  flex-basis: 50%;
  min-width: 280px;

  li a {
    display: flex;
    align-items: center;
    padding: 12px 24px;
  }
}

// Work arround: cant display tag in one line without poping out the frame
.haveTag {
  & > span {
    position: relative;

    .itemName {
      margin-right: 8px;
    }

    .tag {
      padding: 4px;
      border-radius: 2px;

      color: white;

      font-weight: 500;
      font-size: 10px;

      line-height: 10px;

      text-transform: uppercase;

      background-color: $gold-500;
    }
  }
}

.subListTitle {
  display: block;
  padding: 8px 24px;

  color: $gray-400;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
}

.secondarySubList {
  display: grid;
  flex-basis: 100%;
  flex-grow: 1;
  grid-column: 1/3;
  grid-template-columns: repeat(2, 1fr);
  margin: 16px -16px -16px -16px;
  padding: 8px 16px;

  background-color: $gray-50;

  li a {
    min-width: 280px;
    padding: 8px 24px;

    color: $gray-700;

    font-size: 14px;
    line-height: 20px;

    &:hover {
      color: $gray-600;
    }
  }
}

.isLight {
  .menuItem,
  .menuItem > a {
    color: white !important;

    &:hover {
      & > span,
      & > a {
        color: white !important;
      }
    }
  }

  .menuItem.menuItemSubListed > span:after {
    background-image: url('~assets/icons/header/chevron-light.svg');
  }
}

.desktopOnly {
  @media (max-width: 1024px) {
    display: none;
  }
}
</style>
