// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@-webkit-keyframes Header__slide-down___2o22t{0%{transform:translateY(-60px)}to{transform:translateY(0)}}@keyframes Header__slide-down___2o22t{0%{transform:translateY(-60px)}to{transform:translateY(0)}}.Header__header___3w5z0{position:relative;z-index:5;width:100vw}.Header__fixed___XUsR-{position:fixed!important;top:0;-webkit-animation:Header__slide-down___2o22t .3s ease-in-out;animation:Header__slide-down___2o22t .3s ease-in-out}.Header__bannerContainer___2mIj5{position:relative;z-index:5}.Header__banner___2DES7{display:flex;align-items:center;justify-content:center;min-height:37px;padding:8px;color:#866523;text-align:center;background-color:#fdeecf;line-break:strict}.Header__banner___2DES7 a{color:#866523;white-space:nowrap}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"header": "Header__header___3w5z0",
	"fixed": "Header__fixed___XUsR-",
	"slide-down": "Header__slide-down___2o22t",
	"bannerContainer": "Header__bannerContainer___2mIj5",
	"banner": "Header__banner___2DES7"
};
module.exports = ___CSS_LOADER_EXPORT___;
