import { removeCookie } from '~/utils/cookies'
import { COOKIE } from '~/utils/constants'
import linkResolver from '~/utils/prismic/link-resolver'

export const headerMixins = {
  methods: {
    logout() {
      this.$store.commit('user/DELETE')
      removeCookie(COOKIE.LK_CMS)
      window.storage.remove('intercom_token')
      window.storage.remove('user_id')
    },
  },
  computed: {
    logoLink() {
      return linkResolver({
        link_type: 'Document',
        type: 'home_page',
        lang: this.$i18n.localeProperties.prismicIso.toLowerCase(),
      })
    },
  },
}
