import { Plugin } from '@demain-es/lukosiris'

new Plugin({
  projectId: 'CMS Front',
  loadEventData: () => {
    return {
      ci: {
        branch: process.env.BRANCH,
      },
    }
  },
  loadUserData: () => {},
  amplitudeInstance:
    process.env.IS_DEV === 'true' ? 'lukosiris-dev' : 'lukosiris',
  amplitudeCoverKey: process.env.AMPLITUDE_COVER_KEY,
  amplitudeKey: process.env.AMPLITUDE_KEY,
}).initialize({})
