// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RichText__root___32tak strong{font-weight:500}.RichText__root___32tak ol,.RichText__root___32tak ul{color:inherit;font-weight:400;font-size:16px;line-height:24px;padding:16px 0}.RichText__root___32tak ol li,.RichText__root___32tak ul li{position:relative;padding:8px 0}.RichText__root___32tak ol li:before,.RichText__root___32tak ul li:before{position:absolute;left:0;color:#aaa8a1}.RichText__root___32tak ol{counter-reset:list}.RichText__root___32tak ol li{padding-left:32px}.RichText__root___32tak ol li:before{content:counter(list) \".\";counter-increment:list}.RichText__root___32tak ul li{padding-left:25px}.RichText__root___32tak ul li:before{content:\"•\"}.RichText__root___32tak video{width:100%!important;height:auto!important}.RichText__root___32tak>div{position:relative;height:0;margin-top:16px;padding-bottom:56.25%}.RichText__root___32tak>div iframe{position:absolute;top:0;left:0;width:100%;height:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"root": "RichText__root___32tak"
};
module.exports = ___CSS_LOADER_EXPORT___;
