<template>
  <header
    :class="[
      $style.header,
      { [$style.fixed]: $store.state.header.fixedHeader },
    ]"
  >
    <div id="promo-banner" :class="$style.bannerContainer">
      <RichText
        v-if="topBanner && topBanner[0] && topBanner[0].text"
        :text-params="topBanner"
        :class="$style.banner"
      />
    </div>
    <Header2021Q4
      v-if="'2021Q4' === $store.state.page.settings.theme"
      :data="data"
    />
    <Header2021Q4Pink
      v-else-if="'2021Q4-pink' === $store.state.page.settings.theme"
      :data="data"
    />
    <Header2021Q4Dark
      v-else-if="'2021Q4-dark' === $store.state.page.settings.theme"
      :data="data"
    />
    <HeaderDefault v-else :data="data" />
  </header>
</template>

<script>
import Header2021Q4Dark from './Header2021Q4Dark'
import Header2021Q4Pink from './Header2021Q4Pink'
import Header2021Q4 from './Header2021Q4'
import HeaderDefault from './HeaderDefault'

export default {
  name: 'HeaderModule',

  components: {
    Header2021Q4,
    HeaderDefault,
    Header2021Q4Pink,
    Header2021Q4Dark,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    topBanner() {
      return this.$store.state.page.settings.topBanner
    },
  },
}
</script>

<style lang="scss" module>
@keyframes slide-down {
  0% {
    transform: translateY(-60px);
  }
  100% {
    transform: translateY(0);
  }
}
.header {
  position: relative;
  z-index: 5;

  width: 100vw;
}
.fixed {
  position: fixed !important;
  top: 0;

  animation: slide-down 0.3s ease-in-out;
}

.bannerContainer {
  position: relative;
  z-index: 5;
}
.banner {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 37px;
  padding: 8px 8px;

  color: $gold-800;
  text-align: center;

  background-color: $gold-100;
  line-break: strict;
  a {
    color: $gold-800;
    white-space: nowrap;
  }
}
</style>
