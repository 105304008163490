export const excludedRoutes = [/\/optin\/confirm(\/)?$/, /\/en\/compliance(\/)?$/, /\/en\/press(\/)?$/]

export default {
  advices_category_page: {
    'fr-fr': '/conseils',
    'en-fr': '/en/advice',
  },
  advice_page: {
    'fr-fr': '/conseils/guide',
    'en-fr': '/en/advice/guide',
  },
  contact_page: {
    'fr-fr': '/contact',
    'en-fr': '/en/contact',
  },
  error_page: {
    'fr-fr': '/not-found/',
    'en-fr': '/en/not-found/',
  },
  giveback_page: {
    'fr-fr': '/giveback',
    'en-fr': '/en/giveback',
  },
  home_page: {
    'fr-fr': '/',
    'en-fr': '/en',
  },
  home_services_page: {
    'fr-fr': '/home-services',
    'en-fr': '/en/home-services',
  },
  hub_page: {
    // advice hub
    'fr-fr': '/conseils',
    'en-fr': '/en/advice',
  },
  launch_page: {
    'fr-fr': '/lancement',
    'en-fr': '/en/launch',
  },
  legal_page: {
    'fr-fr': '/',
    'en-fr': '/en',
  },
  lukon_termination_page: {
    'fr-fr': '/on-resilie-pour-vous',
    'en-fr': '/en/we-cancel-for-you',
  },
  partners: {
    'fr-fr': '/partenaires',
    'en-fr': '/en/partners',
  },
  perks_page: {
    'fr-fr': '/avantages',
    'en-fr': '/en/perks',
  },
  press_hub: {
    'fr-fr': '/presse',
    'en-fr': '/en/press',
  },
  press_release: {
    'fr-fr': '/presse/release',
    'en-fr': '/en/press/release',
  },
  press_article: {
    'fr-fr': '/presse/article',
    'en-fr': '/en/press/article',
  },
  products_hub: {
    'fr-fr': '/assurance',
    'en-fr': '/en/insurance',
  },
  product_page: {
    'fr-fr': '/assurance',
    'en-fr': '/en/insurance',
  },
  refunds_page: {
    'fr-fr': '/refunds',
    'en-fr': '/en/refunds',
  },
  testimonials_page: {
    'fr-fr': '/testimonials',
    'en-fr': '/en/testimonials',
  },
}
