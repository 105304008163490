<template>
  <div :class="[$style.rightNav]">
    <ProfileDropdown
      v-if="username"
      :data-is-page-scrolled="isPageScrolled"
      :username="username"
      :class="$style.profile"
      @logout="$emit('logout')"
    />

    <a
      v-if="data && !accountMenu && !username"
      :href="getMyLukoLink()"
      :class="[$style.rightButtons, $style.accountButton]"
    >
      {{ data.account_button }}
    </a>

    <div
      v-if="data && accountMenu && !username"
      :class="[$style.rightButtons, $style.accountButton]"
    >
      {{ data.account_button }}
      <div :class="$style.subListContainer">
        <ul :class="$style.subList">
          <li v-for="menu in accountMenu.items" :key="menu.link_text">
            <a :class="$style.subListItem" :href="menu.link.url">
              <PImage
                :image-object="menu.icon"
                loading="lazy"
                :class="$style.subListIcon"
              />
              <span>{{ menu.link_text }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <LkButton
      v-if="!hasWaitlist && data.cover_button"
      :href="
        $store.state.navCta.url ||
        getOnboardingLink(null, { queryParams: [['$.key', contractPk]] })
      "
      :class="[
        $style.coverButton,
        { [$style.isMobileScrolled]: isPageScrolled },
      ]"
      is-small
      :is-outlined="!isPageScrolled"
    >
      {{ $store.state.navCta.text || ctaText }}
    </LkButton>
    <PLink
      v-else-if="hasWaitlist && waitlistCta"
      :class="$style.waitlistCta"
      :link-object="waitlistCta.primary.cta_link"
    >
      {{ $t('waitlistCta') }}
    </PLink>
  </div>
</template>

<script>
import { getOnboardingLink } from '~/mixins/getOnboardingLink'
import { getMyLukoLink } from '~/mixins/getMyLukoLink'
import ProfileDropdown from '~/components/ProfileDropdown'
import { rightNavMixins } from '~/mixins/header/rightNav'

export default {
  name: 'DesktopNavigation',

  components: {
    ProfileDropdown,
  },

  mixins: [getOnboardingLink, getMyLukoLink, rightNavMixins],

  props: {
    data: {
      type: Object,
      required: true,
    },
    isLight: {
      type: Boolean,
      default: false,
    },
    username: {
      type: Object,
      default: null,
    },
    isPageScrolled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      hasWaitlist: this.$store.state.hasWaitlist,
    }
  },
}
</script>

<style lang="scss" module>
@keyframes tada {
  /* keyframe */
  from {
    transform: translateY(-30px);
  }
  /* keyframe */
  to {
    transform: translateY(0);
  }
}
.profile {
  @include below(small) {
    &[data-is-page-scrolled='true'] {
      display: none;
    }
  }
}

.rightNav {
  display: flex;
  .coverButton {
    height: 40px;
    margin-left: 20px;

    font-size: 100%;

    transition: background-color 0.3s;

    &:hover {
      text-decoration: none;
    }
    @include below(small) {
      display: none;
    }
  }
  align-items: center;
}
.isMobileScrolled {
  @include below(small) {
    display: flex !important;

    transform: translateY(-60px);

    animation-name: tada;
    animation-duration: 0.3s;
    animation-timing-function: cubic-bezier(0, 1.68, 0.56, 1.24);
    animation-delay: 0.8s;
    animation-fill-mode: forwards;
  }
}

.accountButton {
  position: relative;

  padding: 15px 0;

  color: $gray-1000;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;

  cursor: pointer;

  transition: color 0.3s;

  &:hover {
    color: $gray-700;
  }
  &:hover > .subListContainer {
    transform: translateY(0);
    opacity: 1;

    pointer-events: all;
  }

  @media (max-width: 1024px) {
    display: none;
  }
}

.subListContainer {
  position: absolute;
  top: 100%;
  left: 0;

  display: flex;
  align-items: center;

  width: 256px;
  border-radius: 8px;

  color: $gray-1000;

  background-color: white;
  box-shadow: 0px 24px 48px rgba(44, 35, 2, 0.24);
  transform: translateY(-8px);
  opacity: 0;

  transition-duration: 0.3s;
  transition-property: opacity, transform;

  pointer-events: none;

  &::before {
    position: absolute;
    top: 8px;
    left: 15%;

    width: 24px;
    height: 8px;

    background-image: url('~assets/icons/header/menu-pointer.svg');
    transform: translate(-50%, -16px);

    transition-duration: 0.3s;
    transition-property: opacity, transform;

    content: '';
  }
}

.subList {
  margin: 12px 10px 12px 10px;
}

.subListItem {
  display: flex;
  align-items: center;

  width: 200px;
  margin: 15px;
  padding: 5px 0;

  color: $gray-1000;
  text-decoration: none;

  cursor: pointer;

  &:hover {
    color: $gray-600;
  }
}
.subListIcon {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}

.waitlistCta {
  display: block;
  margin-left: 32px;
  padding: 8px 16px;
  border: solid 2px $gray-100;
  border-radius: 8px;

  color: $bluko-500 !important;

  &:hover {
    color: $bluko-400 !important;
  }
}
.priceSecondPart {
  @media screen and (max-width: 360px) {
    display: none;
  }
}
</style>
