<template>
  <nuxt-img
    v-if="imageObject.url"
    :src="imageSrc"
    :alt="imageObject.alt"
    :class="[{ [$style.image]: !imageObject.url }]"
    data-luko-tracking="PImage"
    :sizes="sizes"
    :fit="fit"
    :quality="quality"
    :loading="lazy ? 'lazy' : undefined"
  />
</template>

<script>
export default {
  name: 'PImage',

  props: {
    imageObject: {
      type: Object,
      required: true,
    },
    sizes: {
      type: String,
      default: () => '', // "xs:100vw sm:100vw md:50vw lg:400px"
    },
    quality: {
      type: Number,
      default: () => 100,
    },
    fit: {
      type: String,
      default: () => 'cover', // cover, contain, fill, inside, outside
    },
    lazy: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    imageSrc() {
      return this.imageObject.url
        .split('&')
        .filter((u) => !u.includes('rect=') && !u.includes('h='))
        .join('&')
    },
  },
}
</script>

<style lang="scss" module>
.image {
  border: 0;

  background-color: $bluko-50;
}
</style>
