export const state = () => ({
  headerData: null,
  fixedHeader: false,
})

export const mutations = {
  SET_HEADER_DATA(state, data) {
    state.headerData = data
  },
  SET_HEADER_FIXED(state, bool) {
    state.fixedHeader = bool
  },
}
