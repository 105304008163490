<template>
  <section :class="$style.container">
    <Wrapper :class="$style.inner" :style="background">
      <div :class="$style.desc">
        <Title :title-params="data.title" :class="$style.title" />
        <RichText :text-params="data.description" :class="$style.subtitle" />
        <LkButton :href="backToHomeLink">
          {{ data.cta_title }}
        </LkButton>
      </div>
    </Wrapper>
  </section>
</template>

<script>
import { getFormatedLink } from '~/mixins/getFormatedLink'
import Title from '~/components/Title'
import RichText from '~/components/RichText'
import { backgroundStyles } from '~/mixins/backgroundStyles'

export default {
  name: 'ErrorPage',

  components: {
    Title,
    RichText,
  },
  mixins: [getFormatedLink, backgroundStyles],
  props: {
    data: {
      type: Object,
      required: true,
    },
    error: {
      type: Object,
      required: true,
    },
  },

  computed: {
    background() {
      return this.backgroundStyles(this.data?.image?.url, 'sm:550px')
    },
    backToHomeLink() {
      return this.getFormatedLink({
        link_type: 'Document',
        type: 'home_page',
        lang: this.$i18n.localeProperties.prismicIso.toLowerCase(),
      })
    },
  },

  mounted() {
    if (window.analytics) {
      window.analytics.track('Page not found', {
        message: this.error.message,
        statusCode: this.error.statusCode,
        referrer: document.referrer,
        location: document.location.href || document.URL,
        prospect: window.storage.get('about_utm'),
      })
    } else {
      console.debug('Analytics not loaded')
    }
    window.LukoTracking.trackEvent({
      id: 'Page not found',
      properties: {
        error: { message: `Page not found` },
      },
    })
  },
}
</script>

<style lang="scss" module>
.container {
  max-width: inherit;

  margin-top: -64px;
  padding: 0 24px;

  background-color: #fff4ee;
}

.inner {
  display: flex;
  flex-direction: column;
  align-items: center;

  min-height: 720px;
  padding-top: 192px;

  text-align: center;

  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: auto;

  @include below(small) {
    min-height: 630px;
    padding-top: 132px;

    text-align: left;

    background-size: 70vw 60vw;
  }
}

.desc {
  margin: 0;

  @include below(medium) {
    margin: 0 auto;
  }
}

.title h1 {
  max-width: 725px;
  margin: 0 auto;
  margin-bottom: 16px;

  color: $gray-1000;
  font-weight: 500;
  font-size: 52px;
  line-height: 64px;

  @include below(medium) {
    font-size: 40px;
    line-height: 56px;
  }
}

.subtitle {
  margin: 16px 0 48px 0;

  color: $gray-700;

  font-size: 20px;
  line-height: 32px;

  @include below(medium) {
    font-size: 16px;
    line-height: 24px;
  }
}
</style>
