import Vue from 'vue'

import Wrapper from '~/components/Wrapper'
import PImage from '~/components/PImage'
import LkButton from '~/components/LkButton'
import Title from '~/components/Title'
import RichText from '~/components/RichText'

Vue.component('Wrapper', Wrapper)
Vue.component('PImage', PImage)
Vue.component('LkButton', LkButton)
Vue.component('Title', Title)
Vue.component('RichText', RichText)
