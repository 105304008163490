// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fade-down-enter-active,.fade-down-leave-active{transform:translateY(0);transition:opacity .3s,transform .3s}.fade-down-enter,.fade-down-leave-to{transform:translateY(-8px);opacity:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px"
};
module.exports = ___CSS_LOADER_EXPORT___;
