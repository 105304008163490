export const backgroundStyles = {
  computed: {
    backgroundStyles() {
      return (url, sizes) => {
        const imgUrl = this.$img.getSizes(url, {
          sizes: sizes || 'lg:110vw',
          modifiers: {
            quality: 80,
          },
        })
        return {
          backgroundImage: `url(${imgUrl.src})`,
        }
      }
    },
  },
}
