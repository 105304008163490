// How to use:
// => window.events.$emit('setVariation', payload = {key: "", value: ""})

import mitt from 'mitt'

const { on, emit } = mitt()

export default ({ store }) => {
  window.events = {}
  window.events.$on = (...args) => on(...args)
  window.events.$emit = (...args) => emit(...args)

  window.events.$on('setVariation', (payload) => {
    console.debug('Set Variation: ', payload)
    store.commit('variations/UPDATE', payload)
  })
}
