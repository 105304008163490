// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../../assets/icons/header/chevron.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DropDown__menuItem___3S_oO{color:#6b6651;background-color:transparent;transition-duration:.3s;transition-property:background-color,color}.DropDown__menuItem___3S_oO a{color:#2c2302}.DropDown__menuItemTitle___MASH3{cursor:pointer}.DropDown__menuItemTitle___MASH3 span{position:relative;display:inline-block;padding:16px}.DropDown__menuItemTitle___MASH3 span:after{position:absolute;top:50%;width:6px;height:6px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");transform:translate(5px,-50%);transition:transform .3s;content:\"\"}.DropDown__subMenuItem___1iaFD a{display:flex;align-items:center;padding:12px 16px;line-height:20px;text-decoration:none}.DropDown__subMenuItem___1iaFD a img{width:24px;height:24px;margin-right:16px;-o-object-fit:cover;object-fit:cover}.DropDown__haveTag___MRfQT>span .DropDown__itemName___2GQHW{margin-right:8px}.DropDown__haveTag___MRfQT>span .DropDown__tag___tX6fK{padding:4px;border-radius:2px;color:#fff;font-weight:500;font-size:10px;line-height:10px;text-transform:uppercase;background-color:#452e00}.DropDown__menuActive___X2qe8{color:#2c2302;background-color:#f4f3ef}.DropDown__menuActive___X2qe8 .DropDown__menuItemTitle___MASH3 span:after{transform:translate(5px,-50%) rotate(180deg)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"menuItem": "DropDown__menuItem___3S_oO",
	"menuItemTitle": "DropDown__menuItemTitle___MASH3",
	"subMenuItem": "DropDown__subMenuItem___1iaFD",
	"haveTag": "DropDown__haveTag___MRfQT",
	"itemName": "DropDown__itemName___2GQHW",
	"tag": "DropDown__tag___tX6fK",
	"menuActive": "DropDown__menuActive___X2qe8"
};
module.exports = ___CSS_LOADER_EXPORT___;
