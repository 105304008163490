// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MobileNavigation__mobileNavContainer___1Evbx{z-index:99;width:calc(100% - 54px);max-width:320px;background-color:#fff;transition:transform .3s}.MobileNavigation__mobileNavBackdrop___3Mwmb,.MobileNavigation__mobileNavContainer___1Evbx{position:fixed;top:0;left:0;height:100vh}.MobileNavigation__mobileNavBackdrop___3Mwmb{z-index:1;width:100%;background-color:#000;opacity:.4;content:\"\"}.MobileNavigation__mobileNavHeader___2r_cV{display:flex;justify-content:space-between;padding:10px 16px}.MobileNavigation__closeButton___MTvWi{padding:4px;border:none;border-radius:4px;background-color:transparent;cursor:pointer}.MobileNavigation__closeButton___MTvWi img{display:block}.MobileNavigation__accountButton___3p6j5{padding:8px 16px;border:none;border-radius:8px;font-weight:500;font-size:14px;line-height:20px;cursor:pointer}.MobileNavigation__desktopMenuItem___22e2h a{display:block;padding:16px;color:#6b6651;text-decoration:none;transition:color .3s}.MobileNavigation__desktopMenuItem___22e2h a:hover{color:#2c2302}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"mobileNavContainer": "MobileNavigation__mobileNavContainer___1Evbx",
	"mobileNavBackdrop": "MobileNavigation__mobileNavBackdrop___3Mwmb",
	"mobileNavHeader": "MobileNavigation__mobileNavHeader___2r_cV",
	"closeButton": "MobileNavigation__closeButton___MTvWi",
	"accountButton": "MobileNavigation__accountButton___3p6j5",
	"desktopMenuItem": "MobileNavigation__desktopMenuItem___22e2h"
};
module.exports = ___CSS_LOADER_EXPORT___;
