export const state = () => ({
  settings: {
    theme: '',
    removeHeader: false,
    removeFooter: false,
    topBanner: {},
  },
})

export const mutations = {
  UPDATE(state, payload) {
    // page settings came from PageSettings slice in prismic
    payload = payload || {}
    state.settings = payload.primary ? payload.primary : payload
  },
}
