var render = function () {
var _obj, _obj$1, _obj$2, _obj$3, _obj$4, _obj$5, _obj$6;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.titleParams.length)?_c('div',{class:[
    _vm.$style.root,
    ( _obj = {}, _obj[_vm.$style.headline] = _vm.isHeadline, _obj ),
    ( _obj$1 = {}, _obj$1[_vm.$style.headlineBold] = _vm.isHeadlineBold, _obj$1 ),
    ( _obj$2 = {}, _obj$2[_vm.$style.title] = _vm.isTitle, _obj$2 ),
    ( _obj$3 = {}, _obj$3[_vm.$style.largeTitle] = _vm.isLargeTitle, _obj$3 ),
    ( _obj$4 = {}, _obj$4[_vm.$style.extraTitle] = _vm.isExtraTitle, _obj$4 ),
    ( _obj$5 = {}, _obj$5[_vm.$style.megaTitle] = _vm.isMegaTitle, _obj$5 ),
    ( _obj$6 = {}, _obj$6[_vm.$style.gigaTitle] = _vm.isGigaTitle, _obj$6 ) ],attrs:{"data-luko-tracking":"Title"}},[_c('prismic-rich-text',{staticStyle:{"display":"inline"},attrs:{"field":_vm.titleParams}}),_vm._v(" "),_vm._t("default")],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }