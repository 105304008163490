export const getFormatedLink = {
  methods: {
    getFormatedLink(linkObject, targetedSection) {
      const url = new URL(process.env.URL + this.$route.fullPath)

      if (targetedSection) {
        url.hash = `${targetedSection}`
        return url.toString()
      }

      if (linkObject && linkObject.link_type === 'Document') {
        return this.$prismic.asLink(linkObject)
      }

      if (linkObject && linkObject.type) {
        return this.localePath(linkObject || ''.split('_').join('-'))
      }

      if (
        linkObject &&
        linkObject.url &&
        linkObject.url.startsWith('https://calendly.com/')
      ) {
        url.hash = '#calendly'
        return url.toString()
      }

      // This is for target section.
      // Because sometimes content team put # directly in url :(
      if (
        linkObject &&
        linkObject.url &&
        linkObject.url.match(/#.*$/) &&
        linkObject.url.includes('luko.eu') &&
        !linkObject.url.includes('onboard')
      ) {
        url.hash = linkObject.url.match(/#.*$/)[0]
        return url.toString()
      }

      if (linkObject && linkObject.url) {
        return linkObject.url
      }

      return url.toString()
    },
  },
}
