// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".empty__mainRoot___gQlYv{overflow-x:hidden;overflow-y:none}.empty__content___QdiCC{margin:0 auto}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"mainRoot": "empty__mainRoot___gQlYv",
	"content": "empty__content___QdiCC"
};
module.exports = ___CSS_LOADER_EXPORT___;
