import { getCookie, removeCookie } from '~/utils/cookies'
import { COOKIE } from '~/utils/constants'

export default ({ app, store }) => {
  app.mounted = () => {
    const cmsCookie = getCookie(COOKIE.LK_CMS)
    if (!cmsCookie) return

    try {
      const cmsJson = JSON.parse(unescape(cmsCookie))
      store.commit('user/UPDATE', cmsJson)
    } catch (error) {
      removeCookie(COOKIE.LK_CMS)

      store.commit('user/UPDATE', {
        firstname: null,
        lastname: null,
        price: null,
      })
    }
  }
}
