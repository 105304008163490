// Notice at the top of the screen
export const state = () => ({
  type: 'info',
  text: '',
  timeoutId: null,
})

export const mutations = {
  SET_NOTIFICATION(state, { type, text }) {
    clearTimeout(state.timeoutId)
    if (!text) return (state.text = text)

    state.type = type || 'info'
    state.text = text
    state.timeoutId = setTimeout(() => {
      this.commit('notification/SET_NOTIFICATION', { type, text: '' })
    }, 3000)
  },
}
