import frPages, { excludedRoutes as frExcludedRoutes } from './fr-pages.js'
import esPages, { excludedRoutes as esExcludedRoutes } from './es-pages.js'
import dePages, { excludedRoutes as deExcludedRoutes } from './de-pages.js'

export const excludedRoutes = {
  fr: frExcludedRoutes,
  es: esExcludedRoutes,
  de: deExcludedRoutes,
}

export default {
  fr: frPages,
  es: esPages,
  de: dePages,
}
