import Vue from 'vue'

Vue.mixin({
  methods: {
    showIntercom() {
      if (window.analytics) {
        analytics.ready(() => {
          Intercom('show')
        })
      }
    },
    sendIntercomMessage(message) {
      if (window.analytics) {
        analytics.ready(() => {
          Intercom('showNewMessage', message)
        })
      }
    },
  },
})

const registerUserInIntercom = () => {
  const email = window.storage.get('email')
  const intercom_token = window.storage.get('intercom_token')
  if (email && intercom_token && window.analytics) {
    analytics.ready(() => {
      Intercom('update', { email, user_hash: intercom_token })
    })
  }
}

registerUserInIntercom()
