// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".menu-fade-left-enter-active,.menu-fade-left-leave-active{left:0;transition:opacity .3s,left .3s}.menu-fade-left-enter,.menu-fade-left-leave-to{left:-8px;opacity:0}.menu-backdrop-fade-in-enter-active,.menu-backdrop-fade-in-leave-active{transition:opacity .3s}.menu-backdrop-fade-in-enter,.menu-backdrop-fade-in-leave-to{opacity:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px"
};
module.exports = ___CSS_LOADER_EXPORT___;
