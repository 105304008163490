import {
  ONBOARDING_TYPE,
  UNSUPPORTED_FRENCH_DEPARTMENTS,
} from '~/utils/constants'

const countries = {
  FR: 'France',
  ES: 'Spain',
  DE: 'Germany',
  GB: 'United Kingdom',
  IT: 'Italy',
}

const getLinkWithQueryParams = (link, queryParams) => {
  return encodeURI([link, queryParams.join('&')].filter((e) => e).join('?'))
}

export const getOnboardingLink = {
  methods: {
    getOnboardingLink(address, option = { queryParams: [], type: 'home' }) {
      const lang = this.$i18n.locale
      const onboard =
        process.env.IS_DEV === 'true'
          ? `https://devnoscope.luko.eu/${lang}/onboard`
          : `https://www.luko.eu/${lang}/onboard`

      const onboardingUrls = {
        [ONBOARDING_TYPE.home]: `${onboard}`,
        [ONBOARDING_TYPE.mri]: 'https://mri.luko.eu',
        [ONBOARDING_TYPE.mortgage]: 'https://mgi.luko.eu',
        [ONBOARDING_TYPE.pet]: `${onboard}/pets`,
        [ONBOARDING_TYPE.escooter]: `${onboard}/escooter`,
      }
      const url = onboardingUrls[option.type || 'home']
      const queryParams =
        option.queryParams
          ?.filter(([k, v]) => !!v)
          .map(([k, v]) => `${k}=${v}`) || []

      if (!address) return getLinkWithQueryParams(url, queryParams)
      // Don't send address query param if FR uncovered departement
      if (
        address.country_code?.toUpperCase() === 'FR' &&
        option.type === 'mri' &&
        UNSUPPORTED_FRENCH_DEPARTMENTS.find((code) =>
          address.postcode?.startsWith(code)
        )
      ) {
        return getLinkWithQueryParams(url, queryParams)
      }

      // Send address query params + additional query param if exist
      address.country = countries[address.country_code]
      const go_fast = address.country_code?.toUpperCase() !== 'DE'

      const queryParamWithAddress = [
        ...queryParams,
        ...Object.entries(address).map(
          ([k, v]) => `$.home.main_asset.${k}=${v}`
        ),
        `go_fast=${go_fast}`,
      ]

      return getLinkWithQueryParams(url, queryParamWithAddress)
    },
  },
}
