export const state = () => ({
  firstname: null,
  lastname: null,
  pk: null,
})

export const mutations = {
  UPDATE(state, user) {
    state.firstname = user.firstname
    state.lastname = user.lastname
    state.pk = user.pk
  },
  DELETE(state) {
    state.firstname = null
    state.lastname = null
    state.pk = null
  },
}
