// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Title__root___31-Ps h1,.Title__root___31-Ps h2,.Title__root___31-Ps h3{margin-bottom:10px}.Title__headline___32Dnj{font-weight:400}.Title__headline___32Dnj,.Title__headlineBold___1k8-I{color:inherit;font-size:20px;line-height:32px}.Title__headlineBold___1k8-I{font-weight:500}.Title__title___M6D2B{font-size:28px;line-height:40px}.Title__largeTitle___1Tb_B,.Title__title___M6D2B{color:inherit;font-weight:500}.Title__largeTitle___1Tb_B{font-size:32px;line-height:48px}.Title__extraTitle___2bGnb{font-size:40px;line-height:56px}.Title__extraTitle___2bGnb,.Title__megaTitle___18MXc{color:inherit;font-weight:500}.Title__megaTitle___18MXc{font-size:48px;line-height:64px}.Title__gigaTitle___3dxD7{color:inherit;font-weight:500;font-size:64px;line-height:80px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"root": "Title__root___31-Ps",
	"headline": "Title__headline___32Dnj",
	"headlineBold": "Title__headlineBold___1k8-I",
	"title": "Title__title___M6D2B",
	"largeTitle": "Title__largeTitle___1Tb_B",
	"extraTitle": "Title__extraTitle___2bGnb",
	"megaTitle": "Title__megaTitle___18MXc",
	"gigaTitle": "Title__gigaTitle___3dxD7"
};
module.exports = ___CSS_LOADER_EXPORT___;
