<template>
  <div class="nuxt-error" data-luko-tracking="Error">
    <HeaderModule v-if="headerData" :data="headerData" :class="$style.header" />
    <main :class="$style.content">
      <Error v-if="errorData" :data="errorData" :error="error" />
    </main>
    <FooterModule v-if="footerData" :data="footerData" />
  </div>
</template>

<script>
import headerData from '@@/script/prismic/headerData.json'
import footerData from '@@/script/prismic/footerData.json'
import errorData from '@@/script/prismic/errorData.json'

import FooterModule from '~/modules/Footer'
import HeaderModule from '~/modules/Header'
import Error from '~/modules/Error'

export default {
  name: 'NuxtError',

  layout: 'empty',

  components: {
    HeaderModule,
    FooterModule,
    Error,
  },

  props: {
    error: {
      type: Object,
      default: () => ({}),
    },
  },

  fetch() {
    this.$store.commit('page/UPDATE', { theme: '2021Q4-pink' })
  },

  data() {
    const lang = this.$i18n.localeProperties.prismicIso.toLowerCase()
    return {
      headerData: headerData[lang]?.data,
      footerData: footerData[lang]?.data,
      errorData: errorData[lang]?.data,
    }
  },
}
</script>

<style lang="scss" module>
.content {
  margin: 0 auto;
}

.header {
  margin: 0 auto;
}
</style>
