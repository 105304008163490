<template>
  <div v-if="data" data-luko-tracking="footer">
    <Footer2021Q4 :data="data" />
  </div>
</template>

<script>
import Footer2021Q4 from './Footer2021Q4'

export default {
  name: 'FooterModule',

  components: {
    Footer2021Q4,
  },

  props: {
    data: {
      type: Object,
      default: null,
    },
  },
}
</script>
