<template>
  <div
    ref="profileRoot"
    :class="$style.profileRoot"
    data-luko-tracking="ProfileDropdown"
  >
    <button :class="$style.profileButton" @click="onClickOnProfileButton">
      <div :class="$style.profileInitials">
        <span>{{ userInitials }}</span>
      </div>
      <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" :class="$style.profileChevron" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M.293.293a1 1 0 011.414 0L5 3.586 8.293.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" fill="#AAA8A1"/></svg>
    </button>
    <transition name="fade-down">
      <div v-show="isDropdownShown" :class="$style.profileDropdown">
        <a
          :class="$style.profileDropdownItem"
          :href="getMyLukoLink()"
          target="_blank"
          rel="noopener"
        >
          <div :class="$style.profileDropdownItemIcon">
            <PersonIcon class="icon-gray-1000" />
          </div>
          <span>{{ profileTexts.account }}</span>
        </a>
        <a
          :class="$style.profileDropdownItem"
          href="https://faq.luko.eu/"
          target="_blank"
          rel="noopener"
        >
          <div :class="$style.profileDropdownItemIcon">
            <QuestionIcon class="icon-gray-1000" />
          </div>
          <span>{{ profileTexts.faq }}</span>
        </a>
        <hr :class="$style.profileDropdownHr" />
        <button :class="$style.profileDropdownItem" @click="logout">
          <div :class="$style.profileDropdownItemIcon">
            <LogoutIcon class="icon-gray-1000" />
          </div>
          <span>{{ profileTexts.logout }}</span>
        </button>
      </div>
    </transition>
  </div>
</template>

<script>
import PersonIcon from 'LkIcons/Person.vue'
import QuestionIcon from 'LkIcons/Question.vue'
import LogoutIcon from 'LkIcons/Logout.vue'

import { getMyLukoLink } from '~/mixins/getMyLukoLink'

export default {
  name: 'ProfileDropdown',

  components: {
    PersonIcon,
    QuestionIcon,
    LogoutIcon,
  },

  mixins: [getMyLukoLink],

  props: {
    username: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      userInfo: null,
      isDropdownShown: false,
      lastname: this.username ? this.username.lastname : null,
      firstname: this.username ? this.username.firstname : null,
    }
  },

  computed: {
    userInitials() {
      if (!this.firstname || !this.lastname) {
        return null
      }
      const firstnameSplitted = this.firstname.split('-')
      if (firstnameSplitted.length === 2) {
        return firstnameSplitted[0][0] + firstnameSplitted[1][0]
      }
      const LASTNAME = this.lastname.toUpperCase()
      if (LASTNAME.startsWith('DE ') || LASTNAME.startsWith('DU ')) {
        return this.firstname[0] + LASTNAME[3]
      }
      if (LASTNAME.startsWith('DE LA ')) {
        return this.firstname[0] + LASTNAME[6]
      }
      return this.firstname[0] + LASTNAME[0]
    },

    profileTexts() {
      return {
        account:
          this.$i18n.locale === 'fr' ? 'Mon espace personnel' : 'My account',
        faq: this.$i18n.locale === 'fr' ? 'Questions fréquentes' : 'FAQ',
        logout: this.$i18n.locale === 'fr' ? 'Déconnexion' : 'Logout',
      }
    },
  },

  mounted() {
    document.addEventListener('click', this.handleOutsideClick)
  },

  beforeDestroy() {
    document.removeEventListener('click', this.handleOutsideClick)
  },

  methods: {
    logout() {
      this.$emit('logout')
    },

    onClickOnProfileButton() {
      this.isDropdownShown = !this.isDropdownShown
    },

    handleOutsideClick({ target }) {
      if (!this.$refs.profileRoot.contains(target)) {
        this.isDropdownShown = false
      }
    },
  },
}
</script>

<style lang="scss" module>
.profileRoot {
  position: relative;
}

.profileButton {
  display: inline-flex;
  align-items: center;
  padding: 0;
  border: none;

  background: none;
  cursor: pointer;
}

.profileInitials {
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;

  color: $gray-700;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;

  background-color: $gray-100;
}

.profileChevron {
  flex-shrink: 0;
  width: 10px;
  height: 6px;
  margin-left: 6px;
}

.profileDropdown {
  position: absolute;
  top: 48px;
  right: 0;

  width: 312px;
  padding: 16px 0;
  border-radius: 4px;

  background-color: white;
  box-shadow: 0 2px 16px rgba(0, 0, 108, 0.12);

  &::before {
    position: absolute;
    top: 8px;
    right: 12px;

    width: 24px;
    height: 8px;

    background-image: url('~assets/icons/header/menu-pointer.svg');
    transform: translate(-50%, -16px);

    transition-duration: 0.3s;
    transition-property: opacity, transform;

    content: '';
  }
}

.profileDropdownHr {
  margin: 16px 0;
  border: none;
  border-top: 1px solid $gray-75;
}

.profileDropdownItem {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  padding: 12px 40px;
  border: none;

  color: $fake-black-1000;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;

  background-color: white;
  cursor: pointer;

  transition: background-color 200ms linear;

  &:hover {
    background-color: $gray-50;
  }
}

.profileDropdownItemIcon {
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-right: 16px;
}
</style>

<style lang="scss">
.fade-down-enter-active,
.fade-down-leave-active {
  transform: translateY(0);

  transition: opacity 0.3s, transform 0.3s;
}
.fade-down-enter, .fade-down-leave-to /* .fade-leave-active below version 2.1.8 */ {
  transform: translateY(-8px);

  opacity: 0;
}
</style>
