const getPartnerName = (query) => {
  const partner_name = query.partner_name

  if (partner_name) {
    return partner_name
  }

  // Prevent multiple utm_source
  const utm_source = Array.isArray(query.utm_source)
    ? query.utm_source[0]
    : query.utm_source

  const utm_campaign = query.utm_campaign

  if (utm_source && utm_source.toUpperCase() === 'PARTNER' && utm_campaign) {
    return utm_campaign
  }
  return null
}

export default ({ query }) => {
  const partner_name = getPartnerName(query)

  if (partner_name) {
    window.sessionStorage.setItem('partner_name', partner_name)
  }
}
