<template>
  <div :class="[$style.navbar, { [$style.scrolled]: isPageScrolled }]">
    <div :class="$style.navbarInner">
      <button
        v-if="hasBuggerMenu"
        :class="$style.burgerButton"
        @click="isMobileMenuOpen = true"
      >
        <img :src="getBuggerMenu" alt="Icon burger menu" />
      </button>
      <a :class="$style.lukoLogo" :href="logoLink">
        <img :src="getLogo" :alt="data.luko_logo.alt" />
      </a>
      <div :class="$style.menus">
        <MobileNavigation
          v-if="data.body.length || data.desktop_links.length"
          :class="$style.mobileNavigation"
          :data="data"
          :is-mobile-nav-open="isMobileMenuOpen"
          @toggle-menu="isMobileMenuOpen = $event"
        />
        <DesktopNavigation
          :data="data"
          :class="$style.desktopNavigation"
          :is-light="isLight"
        />
        <RightNav
          :username="username"
          :data="data"
          :is-light="isLight"
          :is-page-scrolled="isPageScrolled"
          @logout="logout"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getOnboardingLink } from '~/mixins/getOnboardingLink'
import { headerMixins } from '~/mixins/header'

import { MobileNavigation } from '../components'

import { DesktopNavigation, RightNav } from './components'

export default {
  name: 'Header2021Q4',

  components: {
    MobileNavigation,
    DesktopNavigation,
    RightNav,
  },

  mixins: [getOnboardingLink, headerMixins],

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isMobileMenuOpen: false,
    }
  },

  computed: {
    isPageScrolled() {
      return this.$store.state.header.fixedHeader
    },
    username() {
      const user = this.$store.state.user

      if (user.lastname && user.firstname) {
        return { lastname: user.lastname, firstname: user.firstname }
      }
      return null
    },
    hasBuggerMenu() {
      const menus = this.data.body.filter(
        (s) => s.slice_type === 'secondary_menu_list'
      )
      return menus.length || this.data.desktop_links.length
    },
    getBuggerMenu() {
      if (this.isPageScrolled)
        return require('~/assets/icons/header/burger-menu.svg')
      return require('~/assets/icons/header/burger-menu-light.svg')
    },
    getLogo() {
      if (this.isPageScrolled) return require('~/assets/icons/luko-logo.svg')
      return require('~/assets/icons/luko-logo-light.svg')
    },
    isLight() {
      return !this.isPageScrolled
    },
  },
  watch: {
    isMobileMenuOpen(isOpen) {
      document.body.style.overflow = isOpen ? 'hidden' : 'unset'
    },
  },
}
</script>

<style lang="scss" module>
.navbar {
  padding: 0 24px;

  @include below(small) {
    padding: 0 16px;
  }

  background-color: transparent;
  box-shadow: 0px 0px 0px #2c23021f;

  @include above(large) {
    animation: none;
  }

  &.scrolled {
    background-color: white;

    box-shadow: 0px 2px 16px #2c23021f;

    .navbarInner {
      border-bottom: solid 1px transparent;
    }
  }
}

.navbarInner {
  display: flex;
  align-items: center;
  width: 100%;
  height: 64px;
  margin: 0 auto;
  border-bottom: solid 1px $gray-100;

  transition: border-color 0.3s;

  @include above(large) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1280px;
  }
  @include below(small) {
    height: 56px;
  }
}

.burgerButton {
  padding: 0;
  border: 0;

  background-color: transparent;
  cursor: pointer;

  img {
    display: block;
  }

  @media (min-width: 1024px) {
    display: none;
  }
}

.lukoLogo {
  position: relative;
  left: 0;

  @media (max-width: 1024px) {
    left: 35px;
  }
  @include below(small) {
    left: 20px;
  }

  img {
    display: block;
  }
}

.mobileNavigation {
  @media (min-width: 1024px) {
    display: none;
  }
}

.desktopNavigation {
  @media (min-width: 1024px) {
    display: flex;
  }
}
.menus {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
</style>
