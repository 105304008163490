<template>
  <component
    :is="component"
    :data-luko-tracking="dataLukoTracking"
    :class="[
      { [$style.root]: true },
      { [$style['root--fluid']]: isFluid },
      { [$style['root--fluid-below-small']]: isFluidBelowSmall },
      { [$style['root--light']]: isLight },
      { [$style['root--small']]: isSmall },
      { [$style['root--danger']]: isDanger },
      { [$style['root--success']]: isSuccess },
      { [$style['root--untouched']]: isUntouched },
      { [$style['root--secondary']]: isSecondary },
      { [$style['root--white']]: isWhite },
      { [$style['root--warning']]: isWarning },
      { [$style['root--is-loading']]: isLoading },
      { [$style['root--outlined']]: isOutlined },
    ]"
    :to="to"
    :href="href"
    :disabled="isDisabled"
    :target="target ? target : '_self'"
    :rel="rel"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <span v-if="$slots.icon" :class="[iconColor, $style.icon, { [$style['icon-right']]: hasIconRight }]">
      <slot name="icon" />
    </span>

    <span :class="$style.slot">
      <slot />
    </span>
    <svg width="0" height="0">
      <clipPath id="LK_button-clip">
        <path
          d="M14 4C8.47715 4 4 8.47715 4 14C4 19.5228 8.47715 24 14 24C19.5228 24 24 19.5228 24 14C24 12.8954 24.8954 12 26 12C27.1046 12 28 12.8954 28 14C28 21.732 21.732 28 14 28C6.26801 28 0 21.732 0 14C0 6.26801 6.26801 0 14 0C15.1046 0 16 0.89543 16 2C16 3.10457 15.1046 4 14 4Z"
          fill="#000"
        />
      </clipPath>
    </svg>
  </component>
</template>

<script>
export default {
  name: 'LkButton',
  inheritAttrs: false,
  props: {
    dataLukoTracking: {
      type: String,
      default: 'Button',
    },
    to: {
      type: [String, Object],
      default: null,
    },
    href: {
      type: String,
      default: null,
    },
    rel: {
      type: String,
      default: undefined,
    },
    target: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'submit',
    },
    isFluid: {
      type: Boolean,
      default: false,
    },
    isFluidBelowSmall: {
      type: Boolean,
      default: false,
    },
    isLight: {
      type: Boolean,
      default: false,
    },
    isSecondary: {
      type: Boolean,
      default: false,
    },
    isDanger: {
      type: Boolean,
      default: false,
    },
    isSuccess: {
      type: Boolean,
      default: false,
    },
    isUntouched: {
      type: Boolean,
      default: false,
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
    isWarning: {
      type: Boolean,
      default: false,
    },
    isWhite: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    hasIconRight: {
      type: Boolean,
      default: false,
    },
    isOutlined: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    iconColor() {
      if (this.isDisabled) {
        return 'icon-gray-300'
      }
      if (this.isOutlined) {
        if (this.isWhite) return 'icon-white'
        return 'icon-bluko-500'
      }

      if (this.isWhite || this.isSecondary) {
        return 'icon-gray-1000'
      }

      if (this.isLight) {
        if (this.isDanger) {
          return 'icon-terracota-500'
        }

        return 'icon-bluko-50'
      }

      return 'icon-white'
    },
    component() {
      if (this.to) {
        return 'router-link'
      } else if (this.href) {
        return 'a'
      } else {
        return 'button'
      }
    },
  },
}
</script>

<style lang="scss" module>
.root {
  @include typo-body-bold;
  position: relative;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;

  color: white;
  text-align: center;
  text-decoration: none;

  background-color: $bluko-500;
  outline: none;
  cursor: pointer;

  &:disabled.root {
    color: $gray-300;

    background-color: $gray-50;
    cursor: not-allowed;
  }

  &:disabled.root--secondary {
    background-color: $gray-100;
  }

  &:focus,
  &:hover {
    background-color: $bluko-400;
  }
}

.root.root--is-loading {
  cursor: progress;

  .icon,
  .slot {
    opacity: 0;
  }

  &::before {
    position: absolute;

    width: 28px;
    height: 28px;
    border-radius: 50%;

    background: white;
    background: conic-gradient(white 5%, transparent 0% 30%, white 90%);

    animation: loader_animation 1s infinite linear;

    content: '';
    clip-path: url(#LK_button-clip);
  }
}

.root.root--fluid {
  width: 100%;
}

.root.root--fluid-below-small {
  @include below(small) {
    width: 100%;
  }
}

.root.root--light {
  color: $bluko-500;

  background-color: $bluko-100;

  &:hover,
  &:focus {
    background-color: $bluko-75;
  }
}

.root.root--light.root--is-loading {
  &::before {
    background: conic-gradient($bluko-300 5%, transparent 0% 30%, $bluko-400 90%);
    background-color: transparent;

    content: '';
  }
}

.root.root--danger {
  color: white;

  background-color: $terracota-500;

  &:hover,
  &:focus {
    background-color: $terracota-400;
  }
}

.root.root--light.root--danger {
  color: $terracota-500;

  background-color: $terracota-100;

  &:hover,
  &:focus {
    background-color: $terracota-75;
  }
}

.root.root--light.root--danger.root--is-loading {
  &::before {
    background: conic-gradient($terracota-500 5%, transparent 0% 30%, $terracota-500 90%);
    background-color: $terracota-500;

    content: '';
  }
}

.root.root--success {
  color: white;

  background-color: $mint-500;

  &:hover,
  &:focus {
    background-color: $mint-400;
  }
}

.root.root--untouched {
  color: white;

  background-color: $gray-500;

  &:hover,
  &:focus {
    background-color: $gray-400;
  }
}

.root.root--warning {
  color: white;

  background-color: $orange-500;

  &:focus,
  &:hover {
    background-color: $orange-400;
  }
}

.root.root--small {
  padding: 8px 16px;

  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  .icon svg {
    width: 12px;
    height: 12px;
  }
}

.root.root--white {
  color: $gray-1000;

  background-color: white;

  &:focus,
  &:hover {
    background-color: rgba(white, 0.5);
  }
}

.root.root--secondary {
  color: $gray-1000;

  background-color: $gray-100;

  &:focus,
  &:hover {
    background-color: $gray-75;
  }
}

.root.root--outlined {
  border: 2px solid $bluko-500;

  color: $bluko-500;

  background-color: transparent;

  &:focus,
  &:hover {
    background-color: $bluko-100;
  }
  &.root--white {
    border: 2px solid white;

    color: white;

    &:focus,
    &:hover {
      background-color: rgba(white, 0.3);
    }
  }
}

.icon {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  margin-right: 10px;

  svg {
    width: 20px;
    height: 20px;
  }
}

.icon.icon-right {
  order: 10;
  margin-right: 0;
  margin-left: 10px;
}

@keyframes loader_animation {
  to {
    transform: rotate(360deg);
  }
}
</style>
