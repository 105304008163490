<template>
  <div id="mainRoot" :class="$style.mainRoot" data-luko-tracking="App-website">
    <main :class="$style.content">
      <nuxt />
    </main>
  </div>
</template>

<script>
import { formatMetaDatas } from '~/utils/metaData'

export default {
  name: 'DefaultLayout',

  head() {
    const i18nSeo = this.$nuxtI18nSeo()
    const canonical = {
      rel: 'canonical',
      href: `${process.env.URL}${this.$route.path}/`.replace(/\/\/$/, '/'),
    }
    const defaultSeo = formatMetaDatas(
      this.$store.state.seoData || [],
      this.$route.path
    )

    return {
      htmlAttrs: {
        ...i18nSeo.htmlAttrs,
      },
      title: defaultSeo.title,
      meta: [...i18nSeo.meta, ...defaultSeo.meta],
      link: [canonical],
    }
  },
}
</script>

<style lang="scss" module>
.mainRoot {
  overflow-x: hidden;
  overflow-y: none;
}

.content {
  margin: 0 auto;
}
</style>
