<template>
  <div :class="[$style.menuItem, { [$style.menuActive]: isOpen }]">
    <p :class="$style.menuItemTitle" @click="toggleMenuItem()">
      <span>
        {{ title }}
      </span>
    </p>
    <ul v-show="isOpen" :class="$style.subMenuList">
      <li
        v-for="(link, linkIndex) in links"
        :key="linkIndex"
        :class="$style.subMenuItem"
      >
        <a
          :href="getFormatedLink(link.menu_item_url || link.link)"
          :class="{ [$style.haveTag]: link.is_new_item }"
        >
          <PImage
            v-if="
              (link.menu_item_icon && link.menu_item_icon.url) ||
              (link.icon && link.icon.url)
            "
            :image-object="link.menu_item_icon || link.icon"
            loading="lazy"
            sizes="xs:24px"
          />
          <span>
            <span :class="$style.itemName">
              {{ link.menu_item_name || link.link_text }}
            </span>
            <span v-if="link.is_new_item" :class="$style.tag">
              {{ $t('new') }}
            </span>
          </span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { getFormatedLink } from '~/mixins/getFormatedLink'

export default {
  name: 'MobileNavigationDropDown',

  mixins: [getFormatedLink],

  props: {
    title: {
      type: String,
      default: '',
    },
    links: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      isOpen: false,
    }
  },

  methods: {
    toggleMenuItem() {
      if (this.isOpen) {
        this.isOpen = false
      } else {
        this.isOpen = true
      }
    },
  },
}
</script>

<style lang="scss" module>
.menuItem {
  color: $gray-700;

  background-color: transparent;

  transition-duration: 0.3s;

  transition-property: background-color, color;

  a {
    color: $gray-1000;
  }
}

.menuItemTitle {
  cursor: pointer;

  span {
    position: relative;

    display: inline-block;

    padding: 16px;

    &::after {
      position: absolute;
      top: 50%;

      width: 6px;
      height: 6px;

      background-image: url('~assets/icons/header/chevron.svg');

      transform: translate(5px, -50%);

      transition: transform 0.3s;

      content: '';
    }
  }
}

.subMenuItem {
  a {
    display: flex;
    align-items: center;
    padding: 12px 16px;

    line-height: 20px;
    text-decoration: none;

    img {
      width: 24px;
      height: 24px;
      margin-right: 16px;
      object-fit: cover;
    }
  }
}

// Work arround: cant display tag in one line without poping out the frame
.haveTag {
  & > span {
    .itemName {
      margin-right: 8px;
    }

    .tag {
      padding: 4px;
      border-radius: 2px;

      color: white;

      font-weight: 500;
      font-size: 10px;

      line-height: 10px;

      text-transform: uppercase;

      background-color: $gold-1000;
    }
  }
}

.menuActive {
  color: $gray-1000;

  background-color: #f4f3ef;

  .menuItemTitle span::after {
    transform: translate(5px, -50%) rotate(180deg);
  }
}
</style>
