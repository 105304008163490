<template>
  <div
    v-if="titleParams.length"
    data-luko-tracking="Title"
    :class="[
      $style.root,
      { [$style.headline]: isHeadline },
      { [$style.headlineBold]: isHeadlineBold },
      { [$style.title]: isTitle },
      { [$style.largeTitle]: isLargeTitle },
      { [$style.extraTitle]: isExtraTitle },
      { [$style.megaTitle]: isMegaTitle },
      { [$style.gigaTitle]: isGigaTitle },
    ]"
  >
    <prismic-rich-text :field="titleParams" style="display: inline">
    </prismic-rich-text>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Title',
  props: {
    titleParams: {
      type: Array,
      default: () => [],
    },
    isHeadline: {
      type: Boolean,
      default: false,
    },
    isHeadlineBold: {
      type: Boolean,
      default: false,
    },
    isTitle: {
      type: Boolean,
      default: false,
    },
    isLargeTitle: {
      type: Boolean,
      default: false,
    },
    isExtraTitle: {
      type: Boolean,
      default: false,
    },
    isMegaTitle: {
      type: Boolean,
      default: false,
    },
    isGigaTitle: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" module>
.root h1 {
  margin-bottom: 10px;
}

.root h2 {
  margin-bottom: 10px;
}

.root h3 {
  margin-bottom: 10px;
}

.headline {
  @include typo-headline;
}

.headlineBold {
  @include typo-headline-bold;
}

.title {
  @include typo-title;
}

.largeTitle {
  @include typo-large-title;
}

.extraTitle {
  @include typo-extra-title;
}

.megaTitle {
  @include typo-mega-title;
}

.gigaTitle {
  @include typo-giga-title;
}
</style>
