// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InfoBox__root___3Ka1w{color:inherit;font-weight:400;font-size:16px;line-height:24px;display:flex;align-items:center;width:100%;padding:16px 24px;border-radius:8px;color:#2d50e6;background-color:#d6e3fd}.InfoBox__rootIcon___2Lvrd{width:24px;height:24px;margin-right:16px}.InfoBox__rootContent___nDwCc{flex-grow:1;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content}.InfoBox__rootCloseBtn___1VvKQ{display:flex;align-items:center;justify-content:center;margin:0 0 0 24px;padding:0;border:none;background:none;cursor:pointer}.InfoBox__rootCloseBtnIcon___uUPlh{width:24px;height:24px}.InfoBox__rootIsDanger___1TXHF{color:#d95762;background-color:#fbd8db}.InfoBox__rootIsWarning___6yVgO{color:#e07f48;background-color:#fce1d2}.InfoBox__rootIsSuccess___LuIlD{color:#2a8c7c;background-color:#d5e9e6}.InfoBox__rootIsSmall___1Gw2Q{padding:14px 16px}.InfoBox__rootIsSmall___1Gw2Q .InfoBox__rootIcon___2Lvrd{width:16px;height:16px;margin-right:8px}.InfoBox__rootIsSmall___1Gw2Q .InfoBox__rootContent___nDwCc{color:inherit;font-weight:400;font-size:14px;line-height:20px}.InfoBox__rootIsSmall___1Gw2Q .InfoBox__rootCloseBtn___1VvKQ{margin-left:8px}.InfoBox__rootIsSmall___1Gw2Q .InfoBox__rootCloseBtnIcon___uUPlh{width:16px;height:16px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"root": "InfoBox__root___3Ka1w",
	"rootIcon": "InfoBox__rootIcon___2Lvrd",
	"rootContent": "InfoBox__rootContent___nDwCc",
	"rootCloseBtn": "InfoBox__rootCloseBtn___1VvKQ",
	"rootCloseBtnIcon": "InfoBox__rootCloseBtnIcon___uUPlh",
	"rootIsDanger": "InfoBox__rootIsDanger___1TXHF",
	"rootIsWarning": "InfoBox__rootIsWarning___6yVgO",
	"rootIsSuccess": "InfoBox__rootIsSuccess___LuIlD",
	"rootIsSmall": "InfoBox__rootIsSmall___1Gw2Q"
};
module.exports = ___CSS_LOADER_EXPORT___;
