export const rightNavMixins = {
  methods: {},
  computed: {
    contractPk() {
      return this.$store.state.user.pk
    },

    ctaText() {
      return this.contractPk
        ? this.$t('header.contractCta')
        : this.data.cover_button
    },

    accountMenu() {
      return this.data.body.find(
        (s) => s.slice_type === 'account_button_menu_list'
      )
    },

    waitlistCta() {
      return this.data.body.find((s) => s.slice_type === 'waitlist_cta')
    },
  },
}
