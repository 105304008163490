<template>
  <prismic-rich-text
    v-if="textParams.length"
    :class="$style.root"
    :field="textParams"
    data-luko-tracking="RichText"
  />
</template>

<script>
export default {
  name: 'RichText',
  props: {
    textParams: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style lang="scss" module>
.root {
  strong {
    font-weight: 500;
  }

  ol,
  ul {
    @include typo-body;
    padding: 16px 0;
    li {
      position: relative;

      padding: 8px 0;
      &::before {
        position: absolute;
        left: 0;

        color: $gray-400;
      }
    }
  }

  ol {
    counter-reset: list;
    li {
      padding-left: 32px;
      &:before {
        content: counter(list) '.';
        counter-increment: list;
      }
    }
  }

  ul {
    li {
      padding-left: 25px;
      &:before {
        content: '•';
      }
    }
  }

  video {
    width: 100% !important;
    height: auto !important;
  }

  & > div {
    position: relative;

    height: 0;
    margin-top: 16px;
    padding-bottom: 56.25%; /* 16:9 */

    iframe {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;
    }
  }
}
</style>
